import React from 'react';

class AdminUsageReports extends React.Component {
    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="pl-5 pr-5 reports">
                    <div className="row">
                        <div className="col-12 p-0">
                            <label className="searchDiv"><i className="fa fa-search" aria-hidden="true"> </i><span>|</span>
                                <input type="text" placeholder="Search keyword" />
                            </label>
                        </div>
                    </div>
                    <div className="row userTitle">
                        <div className="col-12">
                            <p className="m-0 userlistlabel">Reports</p>
                            <p className=" userlistSublabel">Choose from the list of reports</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 ">
                            <label className="reportDetails w-100">
                                <span className="reportName">Country / City based license count</span>
                                <span className="viewBtn">View</span>
                            </label>
                        </div>
                        <div className="col-12 ">
                            <label className="reportDetails w-100">
                                <span className="reportName">License issued in 6 months</span>
                                <span className="viewBtn">View</span>
                            </label>
                        </div>
                        <div className="col-12 ">
                            <label className="reportDetails w-100">
                                <span className="reportName">Deactivated Licenses</span>
                                <span className="viewBtn">View</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminUsageReports