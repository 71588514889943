import React from 'react';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class LoaderComp extends React.Component {
    render() {
        return (
            <div>
                {this.props.isLoading ?
                    (
                        <div className="loader">
                            <Loader
                                type="ThreeDots"
                                color="#00BFFF"
                                height={80}
                                width={80}
                            />
                        </div>
                    ) : null
                }
            </div>
        )
    }
}

export default LoaderComp
