import React from 'react'
import '../../../assets/css/UserDetails.css'
import * as Constants from '../../../assets/helpers/constants'
import withOutNotification from '../../../assets/images/Bell.png'
import withNotification from '../../../assets/images/BellNotify.png'
import userIcon from '../../../assets/images/ProfileIcon.png'
import blueBackground from '../../../assets/images/Blu_Background-01.png'
import settingsImg from '../../../assets/images/1SettingUp.png'
import CautionImg from '../../../assets/images/Caution-02.png'
import MailIcon from '../../../assets/images/Caution-03.png'

let sectionStyle = {
    width: "100%",
    height: "121px",
    backgroundImage: `url(${blueBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
};

class UserDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasNotifications: false,
            isNotificationPanel: false,
            userDetails: [],
            shortName : ""
        }
    }

    componentDidMount() {
        if (localStorage.getItem("userDetails") !== null) {
            var userDetails = JSON.parse(localStorage.getItem("userDetails"))
            this.setState({
                userDetails: userDetails[0],
                shortName : userDetails[0].Name.length > 20 ?  userDetails[0].Name.slice(0,20) :userDetails[0].Name
            })
        }
    }

    toggleNotification = () => {
        this.setState({
            isNotificationPanel: !this.state.isNotificationPanel
        })
    }
    
    navigate = () => {
        this.props.checkPath()
        this.props.history.push(Constants.SettingsDashboard)
    }

    render() {
        return (
            < div className="userDetails p-0" >
                {
                    this.state.isNotificationPanel ?
                        (<div>
                            <div className="row">
                                <div className="col-12">
                                    <label className="notificationPanel w-100 text-center">
                                        <img src={withNotification} alt="Notification" />
                                        <span className="text-center notificationTitle">Notification</span>
                                        <span className="text-right closeBtn" onClick={this.toggleNotification}>X</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row mt-4 helpSection">
                                <div className="col-3 pr-0 text-left">
                                    <img src={CautionImg} className="helpIcons" alt="CautionImg" />
                                </div>
                                <div className="col-7 pr-0 text-left">
                                    <p className="helpTitle mb-2">Uploading Experience</p>
                                    <p className="helpSubTitle">Lorem ipsum dolor sit amet, consetetur</p>
                                </div>
                                <div className="col-2 text-center clearBtn">
                                    <p>Clear</p>
                                </div>
                            </div>

                        </div>
                        ) :
                        (<div>
                            <div className="row">
                                <div className="col-10 p-0">
                                    <label className="searchDiv"><i className="fa fa-search" aria-hidden="true"> </i><span>|</span>
                                        <input type="text" placeholder="Search keyword" />
                                    </label>
                                </div>
                                <div className="col-2 p-0">
                                    <div className="notificationBox">
                                        {this.state.hasNotifications ? <img className="withNotification" src={withNotification} alt="Notification" onClick={this.toggleNotification} /> : <img src={withOutNotification} alt="Notification" />}
                                    </div>
                                </div>
                            </div>
                            <div className="row userInformation mt-3">
                                <div className="col-8">
                                    <p className="greetings mb-3">Hello,</p>
                                    <h4 className="userName" title={this.state.userDetails.Name}>
                                        {
                                            this.state.shortName
                                        }
                                    </h4>
                                    <p className="userRole">Role: {this.state.userDetails.Role}</p>
                                </div>
                                <div className="col-4 text-center">
                                    <img src={userIcon} alt="User Icon" className="userIcon" />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-9">
                                    <label className="searchDiv companyDetails text-center">
                                        <p className="m-0">COMPANY</p>
                                        <p className="m-0">{this.state.userDetails.CompanyName}</p>
                                    </label>
                                </div>
                                <div className="col-3 text-center p-0">
                                    <button className="editCompany btn" type="button"> Edit </button>
                                </div>
                            </div>
                            <div className="row mt-5 mb-5">
                                <div className="col-12 pr-0">
                                    <div className="settingsSection" style={sectionStyle} onClick={this.navigate}>
                                        <div className="row">
                                            <div className="col-6 settingsDescription" >
                                                <p className="settingsTitle m-0">Settings</p>
                                                <p className="settingsSubTitle m-0">Set-up your account</p>
                                            </div>
                                            <div className="col-6 p-4 text-center">
                                                <img src={settingsImg} alt="SettignsIcon" className="settingsIcon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row helpSection">
                                <div className="col-6 pr-0 text-left">
                                    <p className="helpText">Help</p>
                                </div>
                                <div className="col-6 pr-0 text-right">
                                    <p className="viewAll">View All <i className="fas fa-angle-right"></i></p>
                                </div>
                            </div>
                            <div className="row mt-4 helpSection">
                                <div className="col-3 pr-0 text-left">
                                    <img src={CautionImg} className="helpIcons" alt="CautionImg" />
                                </div>
                                <div className="col-9 pr-0 text-left">
                                    <p className="helpTitle mb-2">Uploading Experience</p>
                                    <p className="helpSubTitle">Lorem ipsum dolor sit amet, consetetur</p>
                                </div>
                            </div>
                            <div className="row mt-4 helpSection">
                                <div className="col-3 pr-0 text-left">
                                    <img src={MailIcon} className="helpIcons" alt="MailIcon" />
                                </div>
                                <div className="col-9 pr-0 text-left">
                                    <p className="helpTitle mb-2">Setting-up Email Templates</p>
                                    <p className="helpSubTitle">Lorem ipsum dolor sit amet, consetetur</p>
                                </div>
                            </div>
                            <div className="row mt-4 helpSection">
                                <div className="col-3 pr-0 text-left">
                                    <img src={CautionImg} className="helpIcons" alt="CautionImg" />
                                </div>
                                <div className="col-9 pr-0 text-left">
                                    <p className="helpTitle mb-2">Assign license to device</p>
                                    <p className="helpSubTitle">Lorem ipsum dolor sit amet, consetetur</p>
                                </div>
                            </div>
                        </div>)
                }
                <hr className="footerDivHR" />
                <div className="row helpSection footerDiv">
                    <div className="col-12 text-center">
                        <p className="footerSection">CMS Managed by Baetho, All rights reserved.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserDetails