import React from 'react';
import Axios from 'axios'
import LoaderComp from '../../Loader'
import * as Constants from '../../../assets/helpers/constants'
import FileStorageIcon from '../../../assets/images/storage.png'
import '../../../assets/css/MainDashboard.css'
import Moment from 'react-moment';

class MainDashboard extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: false,
            ActiveLicenses: 0,
            ActiveDevices: 0,
            ActiveExperiences: 0,
            allotedSize: 0,
            currentCompanySize: 0,
            activities: [],
            newDateOptions: {
                year: "numeric",
                month: "short",
                day: "2-digit"
            }
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.setState({
                isLoading: true
            }, () => {
                let companyId = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyId
                Axios.get(Constants.BASE_URL + `api/getMainDashboardDetails/${companyId}`).then((result) => {
                    this.setState({
                        ActiveLicenses: result.data.info.LicenseCount ,
                        ActiveDevices :  result.data.info.DevicesCount ,
                        ActiveExperiences : result.data.info.ExperiencesCount,
                        activities : result.data.info.Activities,
                        allotedSize : result.data.info.AllotedSize,
                        isLoading: false
                    })
                })
            })
        } else {
            this.props.history.push(Constants.Login)
        }
    }
    render() {
        return (
            <div className="mainDashboard">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="row">
                    <div className="col-12">
                        <p className="QuickStatsTitle pt-1">Quick Stats</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <div className="quickStats">
                            <p className="title ">Total Licenses</p>
                            <p className="LicensesValue">{this.state.ActiveLicenses}</p>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="quickStats">
                            <p className="title">Total Registered Devices</p>
                            <p className="DevicesValue">{this.state.ActiveDevices}</p>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="quickStats">
                            <p className="title">Active Experiences</p>
                            <p className="ExperiencesValue">{this.state.ActiveExperiences}</p>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="quickStats">
                            <p className="title">File Storage <img src={FileStorageIcon} className="fileStorageIcon" alt="FileStorageIcon" /> </p>
                            <p className="FileStorageValue">{this.state.currentCompanySize}GB of {this.state.allotedSize}GB</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 activityLogDiv">
                        <p className="ActivityLogTitle pt-3 ">Activity Log</p>
                        <div className="actionDiv"></div>
                        <hr className="mt-0" />
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-12 activitiesDiv">
                        {this.state.activities.length > 0 ? this.state.activities.map((activity, index) => {
                            return (
                                <div key={index} className="row activityRow">
                                    <div className="col-2">
                                        <p className="activityTitle">Date</p>
                                        <p className="activityContent">
                                            <Moment format="DD-MMM-YYYY">
                                                {activity.date}
                                            </Moment>
                                        </p>
                                    </div>
                                    <div className="col-7">
                                        <p className="activityTitle">Service</p>
                                        <p className="activityContent">{activity.Service}</p>
                                    </div>
                                    <div className="col-3">
                                        <p className="activityTitle">User</p>
                                        <p className="activityContent">{activity.User}</p>
                                    </div>
                                </div>
                            )
                        }) :
                            null}
                    </div>
                </div>
            </div>
        )
    }
}

export default MainDashboard