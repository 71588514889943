import React from 'react';
import { Link } from "react-router-dom";
import * as Constants from '../../../assets/helpers/constants'
import '../../../assets/css/LeftSidePanel.css'
import '../../../assets/css/AdminDashboardCSS/Index.css'
import logoImg from '../../../assets/images/logo.png';
import LogoutButton from '../../../assets/images/LogoutDown.png'
class AdminLeftSidePanel extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoggedIn: false,
            activeLink: 'dashboard'
        }
    }

    componentDidMount() {
        if (localStorage.getItem("userDetails") !== null) {
            this.setState({
                isLoggedIn: true
            })
        }

        let activeLink = ""
        if (this.props.location.pathname === Constants.AdminLicenseDashboard) {
            activeLink = 'experience'
        } else {
            activeLink = 'dashboard'
        }
        
        this.setState({
            activeLink: activeLink
        })
        this.setState({
            activeLink: activeLink
        })
    }


    logOut = () => {
        localStorage.removeItem('userDetails')
        localStorage.removeItem('rememberme')
        this.props.history.push('/')
    }

    setActive = (id) => {
        this.setState({
            activeLink: id
        })
    }

    render() {
        return (
            <div className="leftSidePanel AdminLeftSidePanel text-center">
                <img src={logoImg} alt="logo" className="logoImg" />
                <div className="navBar">
                    <div className="navIcons">
                        <ul>
                            <li>
                                <Link to={Constants.AdminDashboard} className={this.state.activeLink === 'dashboard' ? "disabled-link" : null}>
                                    <i id="dashboard" className={this.state.activeLink === 'dashboard' ? "fas fa-th-large navLinks active" : "fas fa-th-large navLinks"} onClick={() => { this.setActive("dashboard") }}></i>
                                </Link>
                            </li>
                            <li>
                                <Link to={Constants.AdminLicenseDashboard} className={this.state.activeLink === 'license' ? "disabled-link" : null}>
                                    <i id="license" className={this.state.activeLink === 'license' ? "fas fa-calendar-alt navLinks active" : "fas fa-calendar-alt navLinks"} onClick={(e) => { this.setActive("license") }}></i>
                                </Link>
                            </li>
                            <li>
                                <Link to={Constants.AllPosts}>
                                    <i id="forum" className="fab fa-forumbee"></i>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {this.state.isLoggedIn ? (<img src={LogoutButton} alt="logout" className="logoutButton" onClick={() => this.logOut()} />) : null}
                </div>

            </div>
        )
    }
}

export default AdminLeftSidePanel