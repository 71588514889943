import React from 'react';
import Axios from 'axios'
import { withRouter, Route } from 'react-router-dom'
import * as Constants from '../../assets/helpers/constants'
import RightSidePanel from '../SidePanel/UserSidePanel/rightSidePanel'
import LeftSidePanel from '../SidePanel/UserSidePanel/leftSidePanel'
import Header from '../Header'
import MainDashboard from './dashboard/mainDashboard'
import LicenseDashboard from './dashboard/licenseDashboard'
import ExperienceDashboard from './dashboard/experienceDashboard'
import DeviceDashboard from './dashboard/deviceDashboard'
import SettingsDashboard from './dashboard/settingsDashboard'
import MediaManagement from './dashboard/mediaManagement'
import FileManager from './dashboard/fileManager'
import LoaderComp from '../Loader'

// Admin Dashboard Pages
import AdminDashboard from './AdminDashboard/dashboard'
import AdminLeftSidePanel from '../SidePanel/AdminSidePanel/AdminLeftSidePanel'
import AdminRightSidePanel from '../SidePanel/AdminSidePanel/AdminRightSidePanel'
import AdminLicenseDashboard from './AdminDashboard/AdminLicenseDashboard';

class Contents extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            showLoginDetails: props.pageType === "Login" ? true : false,
            showRecoveryDetails: props.pageType === "Recovery" ? true : false,
            showResetPasswordDetails: props.pageType === "ResetPassword" ? true : false,
            experienceList: [],
            UserInfo: {},
            UserDetailsInfo :[],
            companyName: null,
            files: [],
            foldersFiles: [],
            location: null,
            selectedLicenses: [],
            selectedDevices: [],
            expName: null,
            folderName: null,
            fileManagerDetails: {
                jpegFiles: [],
                pngFiles: [],
                pdfFiles: [],
                xmlFiles: [],
                foldersFiles: [],
                otherFormats: [],
                files: [],
                filteredFiles: [],
                location: null,
                companyName: null
            },
            devicesList: [],
            inactiveDevices: 0,
            activeDevices: 0,
            quickStats: {
                totalLicenses: 0,
                sixMonthsrenewal: 0,
                totalResellers: 0,
                totalCustomers: 0,
                threeMonthsrenewal: 0,
            },
            activeLink: null,
            licenceInfo: {
                isComposer: false,
                isPlayer: false,
                licensesList: null,
                filteredLicenses: [],
                defaultCompany: "",
                companyId: null,
                companyName: "",
                assigned: 0,
                unassigned: 0,
                expired: 0,
            }
        }
    }

    componentDidMount() {
        if (this.props.pageType !== "Login" && this.props.pageType !== "Recovery" && this.props.pageType !== "ResetPassword") {
            if (localStorage.getItem("userDetails") === null) {
                this.props.history.push('/Login');
            } else {
                let userDetails = JSON.parse(localStorage.getItem("userDetails"))
                if (userDetails[0].Role === 'Admin') {
                    this.props.history.push(Constants.AdminDashboard);
                } else {
                    if (this.props.location.pathname.includes("/Contents/") && this.props.location.pathname.length > 15) {
                        this.props.history.push(this.props.location.pathname);
                    }
                }
                this.setState({
                    UserInfo: userDetails[0],
                    UserDetailsInfo : userDetails
                }, () => {
                    if (this.props.location.pathname === "/Admin" || (this.props.location.pathname === "/Admin/")) {
                        this.props.history.push('/Admin/DashBoard');
                    }
                    if (this.props.location.pathname === "/Contents" || (this.props.location.pathname === "/Contents/" && this.props.location.pathname.length === 10)) {
                        this.props.history.push('/Contents/DashBoard');
                    }
                })
            }
        }
        this.checkPath()
    }


    getExperiences = (company) => {
        this.setState({
            isLoading: true
        }, () => {
            var x = company.split("_")
            Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getExperiencs/${x[1]}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        experienceList: result.data.info,
                        companyName: x[0],
                        isLoading: false
                    })
                } else {
                    this.setState({
                        experienceList: [],
                        companyName: x[0],
                        isLoading: false
                    })
                }
            })
        })

    }

    getFileDetails = (folderName = this.state.folderName, expName = this.state.expName) => {
        this.setState({
            isLoading: true
        }, () => {
            let data = {
                expName: expName,
                location: "/" + folderName
            }

            if (folderName === "others") {
                Axios.post(Constants.BASE_URL + 'api/mediaManagement/getFileDetailsofMedia', data).then((result) => {
                    if (result.data.status && result.data.info.length > 0) {
                        this.setState({
                            files: result.data.info,
                            filteredFiles: result.data.info,
                            expName: expName,
                            folderName: folderName,
                            isLoading: false
                        })
                    }
                })
            } else {
                Axios.post(Constants.BASE_URL + 'api/mediaManagement/getFileDetailsofFolder', data).then((result) => {
                    if (result.data.status && result.data.info.length > 0) {
                        this.setState({
                            files: result.data.info,
                            filteredFiles: result.data.info,
                            expName: expName,
                            folderName: folderName,
                            isLoading: false
                        })
                    }
                })
            }
        })
    }

    getFolderDetails = (location = this.state.location) => {
        this.setState({
            isLoading: true
        }, () => {
            let data = {
                "location": location
            }
            Axios.post(Constants.BASE_URL + 'api/mediaManagement/getMediaofCompany', data).then((result) => {
                if (result.data.status) {
                    let response = result.data.info
                    this.setState({
                        foldersFiles: response,
                        files: [],
                        location: location,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            })
        })

    }

    getDevices = (CompanyName, CompanyId) => {
        this.setState({
            isLoading: true,
            devicesList: [],
            selectedDevices: [],
            inactiveDevices: 0,
            activeDevices: 0,
            deviceCompanyId : CompanyId
        }, () => {
            Axios.get(Constants.BASE_URL + `api/DeviceManagement/getDevices/${CompanyId}`).then((result) => {
                if (result.data.status) {
                    var activeDevices = result.data.info.filter((devices) => {
                        return devices.deviceStatus === 1
                    })
                    var inactiveDevices = result.data.info.filter((devices) => {
                        return devices.deviceStatus !== 1
                    })
                    this.setState({
                        devicesList: result.data.info,
                        inactiveDevices: inactiveDevices.length,
                        activeDevices: activeDevices.length,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        devicesList: []
                    })
                }
            })
        })
    }

    selectDevices = (details) => {
        if (details.selectedCount > 0) {
            var array = [];
            details.selectedRows.forEach(element => {
                let id = element.deviceId
                var obj = {
                    id: id,
                    companyId: this.state.deviceCompanyId
                }
                var index = array.findIndex(x => x.id === id)
                if (index !== -1) {
                    array.splice(index, 1);
                } else {
                    array.push(obj);
                }
            })

            this.setState({
                selectedDevices: array
            }, () => {
                if (array.length > 0) {
                    let stringifiedArray = JSON.stringify(array)
                    this.props.history.push(Constants.IndividualDeviceDetails + "/" + stringifiedArray)
                } else {
                    this.props.history.push(Constants.DeviceDashboard)
                }
            });
        } else {
            this.setState({
                selectedDevices: []
            }, () => {
                this.props.history.push(Constants.DeviceDashboard)
            });
        }
    }

    selectedLicense = (details) => {
        if (details.selectedCount > 0) {
            var array = [];
            details.selectedRows.forEach(element => {
                let id = element.Id
                let type = element.License_Type
                var obj = {
                    id: id,
                    type: type,
                    companyId: this.state.licenceInfo.companyId
                }
                var index = array.findIndex(x => x.id === id)
                if (index !== -1) {
                    array.splice(index, 1);
                } else {
                    array.push(obj);
                }
                this.setState({
                    selectedLicenses: array
                }, () => {
                    if (array.length > 0) {
                        let stringifiedArray = JSON.stringify(array)
                        this.props.history.push(Constants.LicenseDetails + "/" + stringifiedArray);
                    } else {
                        this.props.history.push(Constants.LicenseDashboard)
                    }
                });
            });
        } else {
            this.setState({
                selectedLicenses: []
            }, () => {
                this.props.history.push(Constants.LicenseDashboard)
            });
        }
    }

    getLicenses = (value) => {
        this.setState(prevState => ({
            selectedLicenses: [],    
            licenceInfo: {
                licensesList: null,
                filteredLicenses: [],
                defaultCompany: null,
                companyId: null,
                companyName: null,
                assigned: 0,
                unassigned: 0,
                expired: 0,
            }
        }), () => {
            var x = value.split("_")
            Axios.get(Constants.BASE_URL + `api/LicenseManagement/getLicenseList/${x[1]}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        licenceInfo: {
                            licensesList: result.data.info.licensesList,
                            filteredLicenses: result.data.info.licensesList,
                            defaultCompany: value,
                            companyId: x[1],
                            companyName: x[0],
                            assigned: result.data.info.assigned,
                            unassigned: result.data.info.unassigned,
                            expired: result.data.info.expired,
                        }
                    }, () => {
                        this.changeLicenses("Player")
                    })
                }
            })
        })
    }

    changeLicenses = (key) => {
        this.setState(prevState=>({
            selectedLicenses: [],
            licenceInfo: {
                ...prevState.licenceInfo,
                filteredLicenses: [],
            }
        }),()=>{
            let licenses = this.state.licenceInfo.licensesList
            let newArray = []
            newArray = licenses.filter(function (item) {
                return item.License_Type === key;
            });
    
            this.setState(prevStateValue => ({
                licenceInfo: {
                    ...prevStateValue.licenceInfo,
                    filteredLicenses: newArray,
                    isComposer: key === "Composer" ? true : false,
                    isPlayer: key === "Player" ? true : false
                }
            }))
            this.props.history.push(Constants.LicenseDashboard)
        })
       
    }

    getFileManagerFolderDetails = (location = this.state.location) => {
        this.setState({
            isLoading: true,
            fileManagerDetails: {
                files: [],
                filteredFiles: []
            }
        }, () => {
            let data = {
                "location": location
            }
            Axios.post(Constants.BASE_URL + 'api/mediaManagement/getMediaofCompany', data).then((result) => {
                if (result.data.status) {
                    let response = result.data.info
                    this.setState({
                        fileManagerDetails: {
                            files: response,
                            filteredFiles: response,
                            location: location
                        },
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        files: [],
                        filteredFiles: [],
                    })
                }
            })
        })

    }

    searchMedia = (value) => {
        let intialFiles = this.state.fileManagerDetails.files
        let latestFiles = this.state.fileManagerDetails.files

        if (value !== "") {
            latestFiles = intialFiles.filter((media) => {
                return media.name.includes(value)
            })
        }

        this.setState(prevState => ({
            fileManagerDetails: {
                ...prevState.fileManagerDetails,
                filteredFiles: latestFiles
            }
        }))


    }


    // Admin Dashboard Funcitons 
    getQuickStats = () => {
        this.setState({
            isLoading: true
        }, () => {
            Axios.get(Constants.BASE_URL + `api/AdminDashboard/getQuickStats`).then((result) => {
                const data = result.data.info
                this.setState({
                    quickStats: {
                        totalLicenses: data.totalLicenses,
                        sixMonthsrenewal: data.sixMonthsrenewal,
                        totalResellers: data.totalResellers,
                        totalCustomers: data.totalCustomers,
                        threeMonthsrenewal: data.threeMonthsrenewal,
                        isLoading: false
                    }
                })
            })
        })
        this.setState({
            isLoading: false
        })
    }

    checkPath = (id = null) => {
        this.setState({
            activeLink: id
        }, () => {
            let activeLink = ""
            if (this.props.location.pathname === Constants.ExperienceDashboard || this.props.location.pathname === Constants.MediaManagement || this.props.location.pathname === Constants.AddExperience || this.props.location.pathname === Constants.DeviceList) {
                activeLink = 'experience'
            } else if (this.props.location.pathname === Constants.LicenseDashboard || this.props.location.pathname === Constants.LicenseDetails) {
                activeLink = 'license'
            } else if (this.props.location.pathname === Constants.DeviceDashboard || this.props.location.pathname === Constants.IndividualDeviceDetails) {
                activeLink = 'device'
            } else if (this.props.location.pathname === Constants.SettingsDashboard || this.props.location.pathname === Constants.SettingsUserManagement || this.props.location.pathname === Constants.SettingsEmailConfig || this.props.location.pathname === Constants.SettingsUsageReports || this.props.location.pathname === Constants.SettingsSoftwareRelease) {
                activeLink = 'settings'
            } else if (this.props.location.pathname === Constants.FileManager) {
                activeLink = 'filemanager'
            } else {
                activeLink = 'dashboard'
            }
            this.setState({
                activeLink: activeLink
            })
        })

    }



    render() {
        return (
            <div className="contents">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="container-fluid">
                    {(this.state.showRecoveryDetails || this.state.showLoginDetails || this.state.showResetPasswordDetails) ? (
                        <div className="row">
                            <div className="col-12">
                                <div className="backdropDiv"></div>
                            </div>
                        </div>
                    ) : null}

                    {this.state.UserInfo.Role !== "Admin" ?
                        (
                            <div className="row userDashboard">
                                <div className="col-md-9 p-0">
                                    <LeftSidePanel checkPath={this.checkPath} activeLink={this.state.activeLink} UserInfo={this.state.UserInfo} closeFunc={this.closeLeftSidePanels} {...this.props} />
                                    <div className="middleContent pr-4">
                                        <Header UserInfo={this.state.UserInfo} {...this.props} />
                                        <Route exact path={Constants.MainDashboard} component={() => <MainDashboard UserInfo={this.state.UserInfo} {...this.props} />} />
                                        <Route path={Constants.ExperienceDashboard} render={() => <ExperienceDashboard UserInfo={this.state.UserInfo} experienceList={this.state.experienceList} getExperiences={this.getExperiences} {...this.props} />} />
                                        <Route path={Constants.MediaManagement} render={() => <MediaManagement UserInfo={this.state.UserInfo} foldersFiles={this.state.foldersFiles} getFolderDetails={this.getFolderDetails} getFileDetails={this.getFileDetails} filesList={this.state.files} {...this.props} />} />
                                        <Route path={Constants.LicenseDashboard} render={() => <LicenseDashboard selectedLicense={this.selectedLicense} changeLicenses={this.changeLicenses} getLicenses={this.getLicenses} licenceInfo={this.state.licenceInfo} UserInfo={this.state.UserInfo} {...this.props} />} />
                                        <Route path={Constants.DeviceDashboard} render={() => <DeviceDashboard selectDevices={this.selectDevices} UserInfo={this.state.UserInfo} activeDevices={this.state.activeDevices} inactiveDevices={this.state.inactiveDevices} getDevices={this.getDevices} devicesList={this.state.devicesList} {...this.props} />} />
                                        <Route path={Constants.SettingsDashboard} render={() => <SettingsDashboard UserInfo={this.state.UserInfo} {...this.props} />} />
                                        <Route path={Constants.FileManager} render={() => <FileManager searchMedia={this.searchMedia} UserInfo={this.state.UserInfo} details={this.state.fileManagerDetails} getFileManagerFolderDetails={this.getFileManagerFolderDetails} filesList={this.state.files} {...this.props} />} />
                                    </div>
                                </div>
                                <div className="col-md-3 wrapper p-0">
                                    <RightSidePanel UserDetailsInfo= {this.state.UserDetailsInfo} getLicenses={this.getLicenses} licenceInfo={this.state.licenceInfo} checkPath={this.checkPath} UserInfo={this.state.UserInfo} getDevices={this.getDevices} getFileDetails={() => this.getFileDetails()} companyName={this.state.companyName} experienceList={this.state.experienceList} getExperiences={this.getExperiences} {...this.props} />
                                </div>
                            </div>
                        ) :
                        (
                            <div className="row adminDashboard">
                                <div className="col-md-9 p-0">
                                    <AdminLeftSidePanel closeFunc={this.closeLeftSidePanels} {...this.props} UserInfo={this.state.UserInfo} />
                                    <div className="middleContent pr-4">
                                        <Header UserInfo={this.state.UserInfo} {...this.props} />
                                        <Route path={Constants.AdminDashboard} render={() => <AdminDashboard getQuickStats={this.getQuickStats} quickStats={this.state.quickStats} UserInfo={this.state.UserInfo} {...this.props} />} />
                                        <Route path={Constants.AdminLicenseDashboard} render={() => <AdminLicenseDashboard UserInfo={this.state.UserInfo} {...this.props} />} />
                                    </div>
                                </div>
                                <div className="col-md-3 wrapper p-0">
                                    <AdminRightSidePanel UserInfo={this.state.UserInfo} getQuickStats={this.getQuickStats} {...this.props} />
                                </div>
                            </div>)

                    }
                </div>
            </div>
        )
    }
}

export default withRouter(Contents)