import React from 'react';
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import SidePanelFooter from './sidepanelFooter'
import folderIcon from '../../../assets/images/folder.png'
import jpgIcon from '../../../assets/images/JPGUp.png'
import pngIcon from '../../../assets/images/PNGUp.png'
import pdfIcon from '../../../assets/images/PDFUp.png'
import xmlIcon from '../../../assets/images/XMLUp.png'

class MediaDetails extends React.Component {
    constructor() {
        super()
        this.state = {
            media: [],
            mediaId: null,
            requiredType: null,
            CompanyName: null,
            filteredMedia: []
        }
    }

    componentDidMount() {
        let url = this.props.location.pathname.split('/')
        const requiredType = url[url.length - 2]
        const CompanyName = url[url.length - 4]
        const mediaId = url[url.length - 1]
        let data = {
            "CompanyName": CompanyName,
            "requiredType": requiredType
        }
        Axios.post(Constants.BASE_URL + 'api/mediaManagement/getMediaofCompanyMediaLink', data).then((result) => {
            if (result.data.status && result.data.info.length > 0) {
                this.setState({
                    media: result.data.info,
                    filteredMedia: result.data.info,
                    mediaId: mediaId,
                    requiredType: requiredType,
                    CompanyName: CompanyName
                })
            }
        })
    }

    linkMedia = (mediaLocation) => {
        let data = {
            "mediaId": this.state.mediaId,
            "location": mediaLocation,
            "userId" : this.props.UserInfo.UserID
        }
        Axios.put(Constants.BASE_URL + 'api/mediaManagement/setMediaLocation', data).then((result) => {
            if (result.data.status) {
                this.props.getFileDetails()
            }
        })
    }

    filterMedia = (e) => {
        let currentMedia = this.state.media
        let latestMedia = currentMedia.filter((media) => {
            return media.includes(e.target.value)
        })
        this.setState({
            filteredMedia: latestMedia
        })
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="rightSidePanel slide mediaLinkDetails">
                    <div className="signin pl-5 ">
                        <div className="row">
                            <div className="col-12 p-0">
                                <label className="searchDiv"><i className="fa fa-search" aria-hidden="true"> </i><span>|</span>
                                    <input type="text" placeholder="Search keyword" onChange={this.filterMedia} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p className="title m-0">Link Media</p>
                                <p className="subTitle">Link media and content for the experience</p>
                            </div>
                        </div>
                        <div className="mediaContainer">
                            {this.state.requiredType === 'folder' ? (this.state.filteredMedia.map((media, index) => {
                                let stringSplit = media.split("/")
                                let value = stringSplit[stringSplit.length - 2]

                                let location = media.split("/")
                                let finalLocation = location.splice(7).join("/")

                                return (
                                    <div key={index} className="row individualmediaLink" >
                                        <div className="col-3">
                                            <img className="mediaIcon" src={folderIcon} alt="folderIcon" />
                                        </div>
                                        <div className="col-9">
                                            <p className="m-0 p-0 title">{value}</p>
                                            <button type="button" className="linkbutton" title={finalLocation} onClick={() => this.linkMedia(media)}>Link</button>
                                        </div>
                                    </div>
                                )
                            })) : null}

                            {this.state.requiredType === 'file' ? (this.state.filteredMedia.map((media, index) => {
                                let stringSplit = media.split("/")
                                let value = stringSplit[stringSplit.length - 1]
                                let location = media.split("/")
                                let finalLocation = location.splice(7).join("/")

                                // jpgIcon from '../../assets/images/JPGUp.png'
                                // import pngIcon from '../../assets/images/PNGUp.png'
                                // import pdfIcon from '../../assets/images/PDFUp.png'
                                // import xmlIcon 
                                let icon = null

                                if (media.includes(".jpg")) {
                                    icon = jpgIcon
                                } else if (media.includes(".png")) {
                                    icon = pngIcon
                                } else if (media.includes(".pdf")) {
                                    icon = pdfIcon
                                }else if(media.includes(".xml")){
                                    icon = xmlIcon
                                } else{
                                    icon = folderIcon
                                }
                                return (
                                    <div key={index} className="row individualmediaLink" >
                                        <div className="col-3">
                                            <img className="mediaIcon" src={icon} alt="folderIcon" />
                                        </div>
                                        <div className="col-9">
                                            <p className="m-0 p-0 title">{value}</p>
                                            <button type="button" className="linkbutton" title={finalLocation} onClick={() => this.linkMedia(media)}>Link</button>
                                        </div>
                                    </div>
                                )
                            })) : null}
                        </div>
                        <SidePanelFooter />
                    </div>
                </div>
            </div>
        )

    }
}

export default MediaDetails