import React from 'react';
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import LoaderComp from '../../Loader'
import '../../../assets/css/ExperienceDashboard.css'
import AddButton from '../../../assets/images/AddUp.png'
import DeviceListIcon from '../../../assets/images/DevicelistUp.png'
import MediaIcon from '../../../assets/images/MediaUp.png'
import DeleteIcon from '../../../assets/images/trashcan.jpeg'
import Swal from 'sweetalert2'

class ExperienceDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            defaultCompany: "Select Company",
            Experiences: [],
            CompanyList: [],
            companyName: null,
            companyId: null
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getCompanyDetails()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getCompanyDetails = () => {
        this.setState({
            isLoading: true
        }, () => {
            let CompanyName = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyName
            let CompanyId = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyId
            let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
            let role = JSON.parse(localStorage.getItem("userDetails"))[0].Role

            if (role === Constants.ContentManager) {
                this.setState({
                    CompanyList: [{
                        CompanyId: CompanyId,
                        CompanyName: CompanyName
                    }],
                    companyId: CompanyId,
                    companyName: CompanyName,
                    defaultCompany: CompanyName + "_" + CompanyId,
                    isLoading: false
                })
                this.props.getExperiences(CompanyName + "_" + CompanyId)
            } else {
                Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getCompaniesList/${userID}/${CompanyId}`).then((result) => {
                    if (result.data.status) {
                        this.setState({
                            CompanyList: result.data.info.filter((ele, ind) => ind === result.data.info.findIndex(elem => elem.CompanyId === ele.CompanyId)),
                            companyId: CompanyId,
                            companyName: CompanyName,
                            defaultCompany: CompanyName + "_" + CompanyId,
                            isLoading: false
                        })
                        this.props.getExperiences(CompanyName + "_" + CompanyId)
                    }
                })
            }

        })

    }

    showDeviceList = (id) => {
        this.props.history.push(Constants.DeviceList + '/' + id)
    }

    getExp = (value) => {
        var x = value.split("_")
        this.setState({
            defaultCompany: value,
            companyId: x[1],
            companyName: x[0]
        })
        this.props.getExperiences(value)
    }

    addExperience = (id) => {
        this.props.history.push(Constants.AddExperience + '/' + this.state.companyId)
    }

    showMedia = (expName) => {
        this.props.history.push(Constants.MediaManagement + '/' + this.state.companyName + '/' + expName)
    }

    deleteExp = (Id, expName) => {
        Swal.fire({
            title: 'Are you sure you want to delete experience?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                var x = this.state.defaultCompany.split("_")
                let data = {
                    expId: Id,
                    location: x[0] + "/" + expName,
                    userId : JSON.parse(localStorage.getItem("userDetails"))[0].UserID
                }

                Axios.post(Constants.BASE_URL + `api/mediaManagement/deleteExperience`, data).then((result) => {
                    if (result.data.status) {
                        Swal.fire(
                            'Deleted!',
                            'Experience Deleted Sucessfully',
                            'success'
                        )
                        this.props.getExperiences(this.state.companyName + "_" + this.state.companyId)
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Something went wrong. Please try again',
                            showConfirmButton: false,
                            timer: 1000
                        })
                    }
                });
            }
        })
    }

    render() {
        return (
            <div className="ExperienceDashboard" >
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="row" >
                    <div className="col-12 activityLogDiv">
                        <p className="ActivityLogTitle pt-3 ">Published - 2</p>
                        <div className="actionDiv"></div>
                        <hr className="mt-0" />
                    </div>
                </div>
                <div className="row experienceManagementDiv m-0 mt-2">
                    <div className="col-12 m-0">
                        <div className="row">
                            <div className="col-12">
                                <label className="companyDetails">
                                    <span className="selectdiv">Company:
                                        <select onChange={(e) => this.getExp(e.target.value)} value={this.state.defaultCompany} placeholder="Select Company">
                                            {/* <option id="Select Company" disabled > Select Company </option> */}
                                            {this.state.CompanyList.length > 0 ? (this.state.CompanyList.map((company) => {
                                                return <option key={company.CompanyId} id={company.CompanyId} value={company.CompanyName + "_" + company.CompanyId}>{company.CompanyName}</option>
                                            })
                                            )
                                                : null}
                                        </select>
                                    </span>
                                    {this.props.UserInfo.Role !== Constants.ContentManager ?
                                        (<span onClick={() => this.addExperience(this.props.compId)}>
                                            <img src={AddButton} alt="AddButton" />
                                            <p className="p-0 m-0 addExperiennceText">Add Experience </p>
                                        </span>
                                        ) :
                                        null
                                    }
                                </label>
                            </div>
                        </div>
                        <div className="row experienceManagementDetails">
                            <div className="col-12">
                                {this.props.experienceList.length > 0 ?
                                    (
                                        this.props.experienceList.map((experience, index) => {
                                            return (
                                                <div key={index} className="row experienceDetails">
                                                    <div className="col-8">
                                                        <p className="experienceName">Experience Name <span className="divider">|</span> <span className="companyName">{experience.ExperienceName}</span></p>
                                                    </div>
                                                    <div className="col-4 settingsOptions text-center">
                                                        <div>
                                                            <img src={DeviceListIcon} className="center" alt="DeviceListIcon" onClick={(e) => this.showDeviceList(experience.ExperienceId)} />
                                                            <p>Device List</p>
                                                        </div>
                                                        <div>
                                                            <img src={MediaIcon} className="center" alt="MediaIcon" onClick={(e) => this.showMedia(experience.ExperienceName)} />
                                                            <p>Media</p>
                                                        </div>
                                                        {this.props.UserInfo.Role !== Constants.ContentManager ? (
                                                            <div>
                                                                <img src={DeleteIcon} className="center" alt="DeleteIcon" onClick={(e) => this.deleteExp(experience.ExperienceId, experience.ExperienceName)} />
                                                                <p>Delete</p>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                    : null
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        )
    }
}

export default ExperienceDashboard