import React from 'react';
import * as Constants from '../../assets/helpers/constants'

function Header(props) {
    let title = null
    let subTitle = null
    if (props.location.pathname.includes(Constants.ExperienceDashboard) || props.location.pathname.includes(Constants.MediaManagement)) {
        title = 'Manage Experiences'
    } else if (props.location.pathname.includes(Constants.LicenseDashboard) || props.location.pathname.includes(Constants.AdminLicenseDashboard)) {
        title = 'Manage Licenses'
    } else if (props.location.pathname.includes(Constants.DeviceDashboard)) {
        title = 'Manage Devices'
    } else if (props.location.pathname.includes(Constants.SettingsDashboard)) {
        title = 'Settings'
    } else if (props.location.pathname.includes(Constants.FileManager)) {
        title = 'Manage all your files'
    } else {
        title = 'Dashboard'
        subTitle = 'Welcome Back!'
    }
    return (
        <div className="HeaderSection pr-3">
            <div className="row">
                <div className="col-8 text-left">
                    <p className="headerTitle ">{title}</p>
                    {subTitle !== null ? <p className="headerSubTitle">{subTitle}</p> : null}
                </div>
                {props.UserInfo !== undefined && props.UserInfo.Role !== Constants.ContentManager ?
                    (<div className="col-4 text-right p-0">
                        <button type="button" className="headerButton" > Buy Licenses</button>
                    </div>) :
                    null
                }

            </div>
        </div>
    )
}

export default Header