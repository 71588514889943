import React from 'react'
import Axios from 'axios';
import LoaderComp from '../../Loader'
import * as Constants from '../../../assets/helpers/constants'
import '../../../assets/css/LicenseManagement.css'
import leftArrow from '../../../assets/images/Untitled-1-10.png'
import customerDown from '../../../assets/images/CustomerDown.png'
import Swal from 'sweetalert2'

class AdminCompanysSoftwareRelease extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: false,
            companyId: null,
            companyDetails: [],
            playerVersions: [],
            composerVersions: [],
            selectedComposer: "",
            selectedPlayer: ""

        }
    }
    componentDidMount() {
        const companyId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
        if (this.props.UserInfo !== null) {
            this.setState({
                companyId: companyId,
                isLoading: true
            }, () => {

                this.getCompanyDetails(companyId)
                this.getCurrentSoftwareVersions(companyId)
                this.getSoftwareVersions()
                this.setState({
                    isLoading: false
                })
            })
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getCompanyDetails = (companyId) => {
        Axios.get(Constants.BASE_URL + `api/AdminDashboard/getCompanyDetails/${companyId}`).then((result) => {
            if (result.data.status) {
                this.setState({
                    companyId: companyId,
                    companyDetails: result.data.info
                })
            } else {
                this.setState({
                    companyId: companyId
                })
            }
        });
    }

    getSoftwareVersions = () => {
        Axios.get(Constants.BASE_URL + `api/AdminDashboard/getAllSoftwareVersions`).then((result) => {
            if (result.data.status) {
                let playerVersions = []
                let composerVersions = []
                playerVersions = result.data.info.filter(function (item) {
                    return item.Type === 'Player';
                });

                composerVersions = result.data.info.filter(function (item) {
                    return item.Type === 'Composer';
                });

                this.setState({
                    playerVersions: playerVersions,
                    composerVersions: composerVersions
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong.Please try after sometime!!',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        });
    }

    getCurrentSoftwareVersions = (companyId) => {
        Axios.get(Constants.BASE_URL + `api/AdminDashboard/getCurrentSoftwareVersionsOfCompany/${companyId}`).then((result) => {
            if (result.data.status) {
                this.setState({
                    selectedComposer: result.data.info.currentComposerVersion,
                    selectedPlayer: result.data.info.currentPlayerVersion
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong.Please try after sometime!!',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        });
    }

    navigate = (link) => {
        this.props.history.push(link + "/" + this.state.companyId)
    }

    setValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    updateCompanyVersions = () => {
        if(this.state.selectedComposer === ""){
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please select Composer Version for Company!!',
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }
        if(this.state.selectedPlayer === ""){
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please select player Version for Company!!',
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        let data = {
            selectedComposer: this.state.selectedComposer,
            selectedPlayer: this.state.selectedPlayer,
            companyId: this.state.companyId,
            userId: this.props.UserInfo.UserID
        }

        Axios.post(Constants.BASE_URL + `api/AdminDashboard/updateSoftwareVersionsOfCompany`, data).then((result) => {
            if (result.data.status) {
                this.navigate(Constants.AdminCompanyDetails)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong.Please try after sometime!!',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        });
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="pl-5 pr-5 reports companysSoftwareReleases">
                    {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                    <div className="row userTitle">
                        <div className="col-12 pb-2">
                            <label className="backButton" onClick={() => this.navigate(Constants.AdminCompanyDetails)}><img src={leftArrow} alt="leftArrow" /> <span> BACK </span></label>
                        </div>
                        <div className="col-12">
                            <p className="m-0 userlistlabel">Software Release</p>
                            <p className="m-0 userlistSublabel">Choose the version to release an update</p>
                        </div>
                    </div>

                    <div className="row companyDetailsSection">
                        <div className="col-2 customerIcon">
                            <img src={customerDown} alt="customerDown" />
                        </div>
                        <div className="col-10 p-0">
                            <div>
                                <label className='w-100 companyDetailsDiv ml-2'>
                                    <span className="adminCompanyName">{this.state.companyDetails.length > 0 ? this.state.companyDetails[0].Name : null}</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row versionSelectionDiv">
                        <div className="col-5">
                            <p className="selectTitle">Composer</p>
                        </div>
                        <div className="col-7 ">
                            <select className="selectDiv" value={this.state.selectedComposer} onChange={(e) => this.setValue("selectedComposer", e.target.value)}>
                                <option value=""></option>

                                {this.state.composerVersions.length > 0 ? this.state.composerVersions.map((composerDetails, index) => {
                                    return <option key={index} value={composerDetails.Id}>{composerDetails.Version}</option>
                                }) : null}
                            </select>
                        </div>
                    </div>

                    <div className="row versionSelectionDiv">
                        <div className="col-5">
                            <p className="selectTitle">Player</p>
                        </div>
                        <div className="col-7 ">
                            <select className="selectDiv" value={this.state.selectedPlayer} onChange={(e) => this.setValue("selectedPlayer", e.target.value)}>
                                <option value=""></option>
                                {this.state.playerVersions.length > 0 ? this.state.playerVersions.map((playerDetails, index) => {
                                    return <option key={index} value={playerDetails.Id}>{playerDetails.Version}</option>
                                }) : null}
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col p-0">
                            <button type="button" className="shareUpdateBtn" onClick={this.updateCompanyVersions}>Share update</button>
                        </div>
                    </div>

                    <hr className="footerDivHR" />
                    <div className="row helpSection footerDiv">
                        <div className="col-12 text-center">
                            <p className="footerSection">CMS Managed by Baetho, All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminCompanysSoftwareRelease