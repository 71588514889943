// export const BASE_URL = 'http://localhost:5555/'
export const BASE_URL = 'http://cms.baetho.com/'
export const BASE_MEDIA_FOLDER = '/assets/media'
export const InitialPath = '/'
export const Login = '/Login'
export const Recovery = '/Recovery'
export const ResetPassword = '/ResetPassword'
export const Contents = '/Contents'
export const MainDashboard = '/Contents/DashBoard'

// Experience Dashboard
export const ExperienceDashboard = '/Contents/ExperienceDashboard'
export const AddExperience = '/Contents/ExperienceDashboard/AddExperience'
export const DeviceList = '/Contents/ExperienceDashboard/DeviceList'
export const MediaManagement = '/Contents/MediaManagement'

// License Dashboard
export const LicenseDashboard = '/Contents/LicenseDashboard'
export const LicenseDetails = '/Contents/LicenseDashboard/LicenceDetails'

// Device Management
export const DeviceDashboard = '/Contents/DeviceDashboard'
export const IndividualDeviceDetails = '/Contents/DeviceDashboard/DeviceDetails'


// Settings Dashboard
export const SettingsDashboard = '/Contents/SettingsDashboard'
export const SettingsUserManagement = '/Contents/SettingsDashboard/UserManagement'
export const SettingsEmailConfig = '/Contents/SettingsDashboard/EmailConfig'
export const SettingsUsageReports= '/Contents/SettingsDashboard/UsageReports'
export const SettingsSoftwareRelease = '/Contents/SettingsDashboard/SoftwareRelease'

// File Manager
export const FileManager = '/Contents/FileManager'
export const PreviewFile = '/Contents/FileManager/PreviewFile'



/// Admin Dashboard Constants
export const AdminDashboard = '/Admin/DashBoard'
export const AdminContents = '/Admin'

export const AdminUserManagementPanel = '/Admin/DashBoard/UserManagement'
export const AdminUserCreation = '/Admin/DashBoard/CreateUser'
export const AdminCompanyDetails = '/Admin/DashBoard/CompanyDetails'
export const AdminCompanysUsersList = '/Admin/DashBoard/CompanyDetails/UsersList'
export const AdminCompanysLicenseIssue = '/Admin/DashBoard/CompanyDetails/IssueLicenses'
export const AdminCompanysSoftwareRelease = '/Admin/DashBoard/CompanyDetails/ReleaseSoftwares'

export const AdminUsageReportsPanel = '/Admin/DashBoard/Reports'
export const AdminSoftwareReleasePanel = '/Admin/DashBoard/SoftwareRelease'

// Admin license Dashboard
export const AdminLicenseDashboard = '/Admin/LicenseDashboard'



// Forum Dashboard Constants
export const Forum = '/Forum'
export const AllPosts = '/Forum/AllPosts'
export const MyPosts = '/Forum/MyPosts'
export const CreatePost = '/Forum/CreatePost'


/// Internal Variables
export const Admin = 'Admin'
export const ContentManager = 'ContentManager'
export const Reseller = 'Reseller'
export const ComposerUser ='ComposerUser'


// RazorPay key
export const Key = 'rzp_test_29V5QKQjJEuTpb'