import React from 'react'
import Axios from 'axios';
import * as Constants from '../../../assets/helpers/constants'
import LoaderComp from '../../Loader'
import '../../../assets/css/LicenseManagement.css'
import leftArrow from '../../../assets/images/Untitled-1-10.png'
import customerDown from '../../../assets/images/CustomerDown.png'
import userIcon from '../../../assets/images/ProfileIcon.png'
import addUp from '../../../assets/images/AddUp.png'
import Swal from 'sweetalert2'

class AdminCompanysUsersList extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: false,
            companyId: null,
            companyDetails: [],
            usersList: [],
            usersCount: 0
        }
    }
    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            const companyId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
            this.setState({
                companyId: companyId,
                isLoading:true
            }, () => {
                this.getUsersList(companyId)
                this.getCompanyDetails(companyId)
                this.setState({
                    isLoading :false
                })
            })
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getCompanyDetails = (companyId) => {
        Axios.get(Constants.BASE_URL + `api/AdminDashboard/getCompanyDetails/${companyId}`).then((result) => {
            if (result.data.status) {
                this.setState({
                    companyId: companyId,
                    companyDetails: result.data.info
                })
            } else {
                this.setState({
                    companyId: companyId
                })
            }
        });
    }

    getUsersList = (companyId) => {
        Axios.get(Constants.BASE_URL + `api/AdminDashboard/getCompanyUsersList/${companyId}`).then((result) => {
            if (result.data.status) {
                this.setState({
                    usersList: result.data.info,
                    usersCount: result.data.info.length
                })
            } else {

            }
        });
    }

    navigate = (link) => {
        this.props.history.push(link + "/" + this.state.companyId)
    }

    activateDeActivateUser = (userId,action) => {
        let data  = {
            userId : userId,
            requiredAction : action
        }
        Axios.put(Constants.BASE_URL + `api/AdminDashboard/activate_deactivate_User`,data).then((result) => {
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: result.data.data,
                    showConfirmButton: false,
                    timer: 1000
                })
                this.getUsersList(this.state.companyId)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: result.data.data,
                    showConfirmButton: false,
                    timer: 1000
                })
                this.getUsersList(this.state.companyId)
            }
        });
    }

    addUser = () =>{
        this.props.history.push(Constants.AdminUserCreation)
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="pl-5 pr-5 reports companyDetailsAdmin companyUsersListAdmin">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                    <div className="row">
                        <div className="col-12 p-0">
                            <label className="searchDiv"><i className="fa fa-search" aria-hidden="true"> </i><span>|</span>
                                <input type="text" placeholder="Search keyword" />
                            </label>
                        </div>
                    </div>
                    <div className="row userTitle ">
                        <div className="col-12 pb-2">
                            <label className="backButton" onClick={() => this.navigate(Constants.AdminCompanyDetails)}><img src={leftArrow} alt="leftArrow" /> <span> BACK </span></label>
                        </div>
                        <div className="col-12">
                            <p className="m-0 userlistlabel">Company Details</p>
                            <p className="m-0 userlistSublabel">All details related to the company</p>
                        </div>
                    </div>

                    <div className="row companyDetailsSection">
                        <div className="col-2 customerIcon">
                            <img src={customerDown} alt="customerDown" />
                        </div>
                        <div className="col-10 p-0">
                            <div>
                                <label className='w-100 companyDetailsDiv ml-2'>
                                    <span className="adminCompanyName">{this.state.companyDetails.length > 0 ? this.state.companyDetails[0].Name : null}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 adminUserlistDetails">
                            <label className="w-100">
                                <span className="totalUsersText">Total Users</span>
                                <span className="totalUsersValue">{this.state.usersCount}</span>
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 adminUserlist">
                            <div className="row addUserDetails">
                                <div className="col-12" onClick={this.addUser}>
                                    <span className="userListTitle">User List</span>
                                    <span className="addUserDiv"><img src={addUp} alt="addUp" />Add User</span>
                                </div>
                            </div>
                            <div className="row indvUsersDiv">
                                <div className="col-12">
                                    <div className="row ">
                                        {this.state.usersList.length > 0 ? (this.state.usersList.map((user, index) => {
                                            return (
                                                <div className="col-12" key={index}>
                                                    <div className="row indvUsers w-100">
                                                        <div className="col-2 ">
                                                            <img src={userIcon} alt="userIcon" className="userIcon" />
                                                        </div>
                                                        <div className="col-8">
                                                            <p className="username p-0 m-0">{user.Name}</p>
                                                            <p className="userRole p-0 m-0">Role: {user.Role}</p>
                                                        </div>
                                                        <div className="col-2 margin-auto p-0">
                                                            <button type="button" className="deactivateUserBtn" >{user.Is_Active ? <span onClick={()=>this.activateDeActivateUser(user.Id,false)}>DeActivate</span> : <span onClick={()=>this.activateDeActivateUser(user.Id,true)}>Activate</span>}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="footerDivHR userListfooter" />
                    <div className="row helpSection footerDiv">
                        <div className="col-12 text-center">
                            <p className="footerSection">CMS Managed by Baetho, All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminCompanysUsersList