import React from 'react';
import '../../../assets/css/LicenseManagement.css'
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import copyImg from '../../../assets/images/DuplicateDown.png'
import DataTable from 'react-data-table-component';
import Moment from 'react-moment';
import LoaderComp from '../../Loader'
import Swal from 'sweetalert2'

class AdminLicenseDashboard extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: false,
            isComposer: false,
            isPlayer: false,
            defaultCompany: "Select Company",
            Experiences: [],
            CompanyList: [],
            licensesList: [],
            filteredLicenses: [],
            companyName: null,
            assigned: 0,
            unassigned: 0,
            expired: 0,
            columns: [
                {
                    name: 'Issued On',
                    grow: 1.5,
                    cell: row => <div data-tag="allowRowEvents"><Moment format="DD-MMM-YYYY">
                        {row.Created_On}
                    </Moment></div>,
                    sortable: true,
                },
                {
                    name: 'License Key #',
                    grow: 2.5,
                    cell: row => <div data-tag="allowRowEvents" className="licenceKeyDiv" title="Click To Copy Licence Key">
                        <span className="key">{row.licenseKey}</span>
                        <span onClick={() => this.copyLicenseKey(row.licenseKey)} className="copyImg">
                            <img src={copyImg} alt="copyImg" />
                        </span>
                        <span className="keySeperator">|</span>

                    </div>,
                    selector: 'licenseKey',
                    sortable: true
                },
                {
                    name: 'License Type',
                    grow: 2,
                    cell: row => <div data-tag="allowRowEvents">{row.License_Type}</div>,
                    sortable: true,
                },
                {
                    name: 'Assigned On',
                    grow: 1.5,
                    cell: row => <div data-tag="allowRowEvents"><Moment format="DD-MMM-YYYY">
                        {row.Start_Date}
                    </Moment></div>,
                    selector: 'Start_Date',
                    sortable: true
                },
                {
                    name: 'Expire Date',
                    grow: 1.5,
                    cell: row => <div data-tag="allowRowEvents"><Moment format="DD-MMM-YYYY">
                        {row.Expiry_Date}
                    </Moment></div>,
                    selector: 'Expiry_Date',
                    sortable: true
                },
                {
                    name: 'Action',
                    grow: 3,
                    cell: (row) => (<div>
                        <label>
                            <button type="button" className="renewBtn" onClick={() => this.renewLicense(row.Id)}>Renew</button><span>|</span>
                            {row.Device_Id !== null || row.Device_Id !== null ? (<button type="button" className="unAssignBtn" onClick={() => this.unAssignLicense(row.Id)}>UnAssign | </button>) : null }
                            {row.Is_Active ? (<button type="button" className="suspendBtn" onClick={() => this.suspendLicense(row.Id)}>Suspend</button>) : (<button type="button" className="activateBtn" onClick={() => this.activateLicense(row.Id)}>Activate</button>)}

                        </label>
                    </div>),
                }
            ]

        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getCompanyDetails()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    renewLicense = (id) => {

    }

    unAssignLicense = (id) => {
        this.setState({
            isLoading : true
        },()=>{
            let data = {
                licenseId: id
            }
            Axios.put(Constants.BASE_URL + `api/adminDashboard/unAssignLicense`, data).then((result) => {
                if (result.status) {
                    // alert("License Unassigned Sucessfully")
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'License Unassigned Sucessfully',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.setState({
                        isLoading : false
                    },()=>{
                        this.getLicenses(this.state.companyName + "_" + this.state.companyId)
                    })
                }
            });
        })
        
    }

    suspendLicense = (id) => {
        this.setState({
            isLoading : true
        },()=>{
            let data = {
                licenseId: id
            }
            Axios.put(Constants.BASE_URL + `api/adminDashboard/suspendLicense`, data).then((result) => {
                if (result.status) {
                    // alert("License Suspended Sucessfully")
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'License Suspended Sucessfully',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.setState({
                        isLoading : false
                    },()=>{
                        this.getLicenses(this.state.companyName + "_" + this.state.companyId)
                    })
                }
            });
        })
        
    }

    activateLicense = (id) => {
        this.setState({
            isLoading : true
        },()=>{
            let data = {
                licenseId: id
            }
            Axios.put(Constants.BASE_URL + `api/adminDashboard/activateLicense`, data).then((result) => {
                if (result.status) {
                    // alert("License Activated Sucessfully")
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'License Activated Sucessfully',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.setState({
                        isLoading : false
                    },()=>{
                        this.getLicenses(this.state.companyName + "_" + this.state.companyId)
                    })
                    
                }
            });
        })
        
    }

    copyLicenseKey = (value) => {
        navigator.clipboard.writeText(value)
    }

    getCompanyDetails = () => {
        this.setState({
            isLoading : true
        },()=>{
            Axios.get(Constants.BASE_URL + `api/AdminDashboard/getCompaniesList`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        CompanyList: result.data.info,
                        defaultCompany: result.data.info[0].Name + "_" + result.data.info[0].Id,
                        companyName: result.data.info[0].Name,
                        companyId: result.data.info[0].Id,
                        isLoading : false
                    })
                    this.getLicenses(result.data.info[0].Name + "_" + result.data.info[0].Id)
                }
            })
        })


       
    }

    handleAction = (id) => {
        // this.props.history.push(Constants.LicenseDetails + "/" + id);
    }

    getLicenses = (value) => {
        this.setState({
            isLoading : true
        },()=>{
            var x = value.split("_")
            Axios.get(Constants.BASE_URL + `api/LicenseManagement/getLicenseList/${x[1]}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        licensesList: result.data.info.licensesList,
                        filteredLicenses: result.data.info.licensesList,
                        defaultCompany: value,
                        companyId: x[1],
                        companyName: x[0],
                        assigned: result.data.info.assigned,
                        unassigned: result.data.info.unassigned,
                        expired: result.data.info.expired,
                        isLoading : false
                    })
                }
            })
        })
       
    }

    changeLicenses = (key) => {
        this.setState({
            [key]: !this.state[key],
            isLoading : true
        }, () => {
            let licenses = this.state.licensesList
            let newArray = []
            if (this.state.isComposer && !this.state.isPlayer) {
                newArray = licenses.filter(function (item) {
                    return item.License_Type === 'Composer';
                });
            } else if (this.state.isPlayer && !this.state.isComposer) {
                newArray = licenses.filter(function (item) {
                    return item.License_Type === 'Player';
                });
            } else {
                newArray = licenses
            }
            this.setState({
                filteredLicenses: newArray,
                isLoading : false
            })

        })
    }

    render() {
        return (
            <div className="LicenseDashboard adminLicenseDashboard">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null} 
                <div className="row">
                    <div className="col-12 activityLogDiv ">
                        <p className="licenseCount pt-3 ">
                            <span>Assigned - {this.state.assigned}</span>
                            <span>Unassigned - {this.state.unassigned}</span>
                            <span>Expired - {this.state.expired}</span></p>
                        <div className="actionDiv"></div>
                        <hr className="mt-0" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <label className="companyDetails">
                            <span className="selectdiv">Company:
                                <select onChange={(e) => this.getLicenses(e.target.value)} value={this.state.defaultCompany} placeholder="Select Company">
                                    {/* <option id="Select Company" disabled > Select Company </option> */}
                                    {this.state.CompanyList.length > 0 ? (this.state.CompanyList.map((company) => {
                                        return <option key={company.Id} id={company.Id} value={company.Name + "_" + company.Id}>{company.Name}</option>
                                    })
                                    )
                                        : null}
                                </select>
                            </span>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" checked={this.state.isComposer} onChange={() => this.changeLicenses("isComposer")} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Composer</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" checked={this.state.isPlayer} onChange={() => this.changeLicenses("isPlayer")} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Player</label>
                            </div>
                        </label>
                    </div>
                </div>
                <div className="row licensesDiv">
                    <div className="col-12">
                        {this.state.filteredLicenses.length > 0 ?
                            <DataTable
                                columns={this.state.columns}
                                data={this.state.filteredLicenses}
                                progressPending={this.state.licensesList != null ? false : true}
                                pagination={true}
                            /> : null
                        }
                    </div>
                </div>
            </div>)
    }
}

export default AdminLicenseDashboard