import React from 'react';
import Axios from 'axios';
import LoaderComp from '../../Loader'
import * as Constants from '../../../assets/helpers/constants'
import SidePanelFooter from './sidepanelFooter'

class PreviewFile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            let url = this.props.location.pathname.toString()
            let details = JSON.parse(url.slice(url.indexOf("["), -1) + "]")
            let data = details[0]
            this.setState({
                fileName: data.file
            }, () => {
                Axios.post(Constants.BASE_URL + "api/mediaManagement/previewFile", data)
                    .then((res) => {
                        document.getElementById("previewImage").src = res.data.info;
                    })
            })

        } else {
            this.props.history.push(Constants.Login)
        }
    }

    closePreview = () => {
        this.props.history.push(Constants.FileManager)
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="signin pl-5 addExperience">
                    <p className="loginTitle mb-0">Preview File <span className="closeSideTab" onClick={this.closePreview}> X </span></p>
                    <p className="loginSubTitle mb-0">{this.state.fileName}</p>
                    <iframe id="previewImage" src="" title="Title" width="100%" height="500px"></iframe>
                    <SidePanelFooter />
                </div>
            </div>
        )
    }
}

export default PreviewFile