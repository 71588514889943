import React from 'react'
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import LoaderComp from '../../Loader'
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import Moment from 'react-moment';
import fileDownload from 'js-file-download'

import '../../../assets/css/FileManager.css'

import trashCan from '../../../assets/images/trash.png'
import uploadNewFolder from '../../../assets/images/AddUp.png'
import uploadIcon from '../../../assets/images/UploadUp.png'
import dropdownImg from '../../../assets/images/Untitled-1-10.png'
import jpgIcon from '../../../assets/images/JPGUp.png'
import pngIcon from '../../../assets/images/PNGUp.png'
import pdfIcon from '../../../assets/images/PDFUp.png'
import xmlIcon from '../../../assets/images/XMLUp.png'
import folderIcon from '../../../assets/images/folder.png'


class FileManager extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            defaultCompany: "Select Company",
            isLoading: false,
            location: null,
            Experiences: [],
            CompanyList: [],
            selectedFiles: [],
            companyName: "",
            jpegFiles: this.props.details.jpegFiles,
            pngFiles: this.props.details.pngFiles,
            pdfFiles: this.props.details.pdfFiles,
            xmlFiles: this.props.details.xmlFiles,
            foldersFiles: this.props.details.foldersFiles,
            otherFormats: this.props.details.otherFormats,
            breadCrumb: null,
            initialName: "",
            finalName: "",
            file: null,
            columns: [
                {
                    name: 'Select',
                    grow: 1,
                    center: 1,
                    cell: (row, index) => <div data-tag="allowRowEvents" className="devicesSlNo text-left"><input type="checkbox" name="rowSelectCheckBox" value={row.name} onClick={() => this.selectedFiles(row.name, row.type)} /></div>,
                    sortable: false,
                },
                {
                    name: 'Type',
                    grow: 1,
                    center: 1,
                    cell: (row, index) => {
                        let Filetype = row.type
                        let iconValue = null
                        if (Filetype === "Folder") {
                            iconValue = <img src={folderIcon} alt="folderIcon" />
                        } else if (Filetype === ".png" || Filetype === ".PNG") {
                            iconValue = <img src={pngIcon} alt="pngIcon" />
                        } else if (Filetype === ".pdf" || Filetype === ".PDF") {
                            iconValue = <img src={pdfIcon} alt="pdfIcon" />
                        } else if (Filetype === ".xml" || Filetype === ".XML") {
                            iconValue = <img src={xmlIcon} alt="xmlIcon" />
                        } else if (Filetype === ".jpg" || Filetype === ".JPG") {
                            iconValue = <img src={jpgIcon} alt="jpgIcon" />
                        }
                        return (<div data-tag="allowRowEvents" className="osIcon text-left" onClick={Filetype === "Folder" ? () => this.navigateFolder(row.name) : () => this.previewFile(row.name)}>{iconValue}
                        </div>)
                    },
                    selector: 'type',
                    sortable: true
                },
                {
                    name: 'Name ',
                    grow: 3,
                    center: 1,
                    cell: (row, index) => <div data-tag="allowRowEvents" key={index}>
                        <p onDoubleClick={(e) => this.handleDoubleClick(row.name,row.type)}>{row.name}</p>
                    </div >
                    ,
                    selector: 'name',
                    sortable: true
                },
                {
                    name: 'Date Modified',
                    grow: 1,
                    center: 1,
                    cell: row => <div data-tag="allowRowEvents" className="devicesStatus text-left">
                        <Moment format="DD-MM-YYYY hh:mm">
                            {row.timeStamp}
                        </Moment>
                    </div>,
                    selector: 'timeStamp',
                    sortable: true
                },

                {
                    name: 'Size',
                    grow: 1,
                    center: 1,
                    cell: row => <div data-tag="allowRowEvents" className="devicesExpName text-left">{row.type !== "Folder" ? this.formatBytes(row.size) : null}</div>,
                    selector: 'size',
                    sortable: true
                },
                {
                    name: 'Action',
                    grow: 2,
                    center: 1,
                    cell: row => <div data-tag="allowRowEvents" className="devicesTagName text-center">
                        {row.type === "Folder" ? <button type="button" className="view" onClick={() => this.navigateFolder(row.name)} ><b>View</b></button> : <button type="button" className="view" onClick={() => this.previewFile(row.name)} ><b>Preview</b></button>}
                        <button type="button" className="download" onClick={() => this.downloadFile(row.name, row.type)}><b>Download</b></button>
                    </div>,
                    sortable: false
                }
            ]
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            let CompanyName = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyName
            let CompanyId = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyId
            let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
            this.getCompanyDetails(CompanyId, CompanyName, userID)
            this.props.getFileManagerFolderDetails(CompanyName)
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getCompanyDetails = (CompanyId, CompanyName, userID) => {
        this.setState({
            isLoading: true
        }, () => {

            let role = JSON.parse(localStorage.getItem("userDetails"))[0].Role
            if (role === Constants.ContentManager) {
                this.setState({
                    CompanyList: [{
                        CompanyId: CompanyId,
                        CompanyName: CompanyName
                    }],
                    companyId: CompanyId,
                    companyName: CompanyName,
                    defaultCompany: CompanyName + "_" + CompanyId,
                    location: CompanyName,
                    breadCrumb: CompanyName,
                    isLoading: false
                })
            } else {
                Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getCompaniesList/${userID}/${CompanyId}`).then((result) => {
                    if (result.data.status) {
                        this.setState({
                            CompanyList: result.data.info,
                            companyId: CompanyId,
                            companyName: CompanyName,
                            defaultCompany: CompanyName + "_" + CompanyId,
                            location: CompanyName,
                            breadCrumb: CompanyName,
                            isLoading: false
                        })
                    }
                })
            }
        })
    }

    navigateFolder = (folderName) => {
        let location = this.state.location + "/" + folderName
        let locationSplit = location.split("/")
        var x = locationSplit.join("/")
        this.setState({
            location: this.state.location + "/" + folderName,
            breadCrumb: x,
            files: []
        })
        this.props.history.push(Constants.FileManager)
        this.props.getFileManagerFolderDetails(this.state.location + "/" + folderName)
    }

    navback = () => {
        let currentLocation = this.state.location;
        let locationSplit = currentLocation.split("/")
        if (locationSplit.length <= 1) {
            this.props.history.push(Constants.FileManager)
        } else {
            locationSplit.pop()
            let finalLocation = locationSplit.join("/")
            var x = locationSplit.join("/")
            this.setState({
                location: finalLocation,
                breadCrumb: x,
                files: []
            })
            this.props.history.push(Constants.FileManager)
            this.props.getFileManagerFolderDetails(finalLocation)
        }
    }

    addNewFolder = () => {
        Swal.fire({
            title: 'Create New Folder',
            input: 'text',
            inputValue: "",
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'Please enter the name of folder!'
                } else {
                    if (!/[^a-zA-Z0-9]/.test(value)) {
                        this.createFolder(value)
                    } else {
                        return 'Should not contain spaces or Special Characters'
                    }
                }
            }
        })
    }

    createFolder = (value) => {
        this.setState({
            isLoading: true
        }, () => {
            let data = {
                "location": this.state.location + "/" + value
            }
            Axios.post(Constants.BASE_URL + 'api/mediaManagement/checkCompanyExists', data).then((result) => {
                if (result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Folder Created Sucessfully",
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.props.getFileManagerFolderDetails(this.state.location)
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: result.data.info,
                        showConfirmButton: false,
                        timer: 1000
                    })
                }
                this.setState({
                    isLoading: false
                })
            })
        })
    }

    changeCompany = (e) => {
        this.setState({
            companyName: e.target.value,
            location: e.target.value,
            breadCrumb: e.target.value
        })
        this.props.getFileManagerFolderDetails(e.target.value)
    }

    handleDoubleClick = (e,type) => {
        this.setState({
            initialName: e,
            finalName: e
        }, () => {
            this.renameFolderValue(type)
        })
    }

    renameFolderValue = (type) => {
        let title = ""
        if(type === "Folder"){
            title = "Rename Folder"
        }else{
            title = "Rename File"
        }
        Swal.fire({
            title: title,
            input: 'text',
            inputValue: this.state.initialName,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'Please enter the name of folder!'
                } else {
                    if(type === "Folder"){
                        if (!/[^a-zA-Z0-9]/.test(value)) {
                            this.renameFolder(value)
                        } else {
                            return 'Should not contain spaces or Special Characters'
                        }
                    }else{
                        this.renameFolder(value)
                    }
                }
            }
        })
    }

    renameFolder = (newValue) => {
        this.setState({
            isLoading: true
        }, () => {
            let data = {
                "currentName": this.state.initialName,
                "finalName": newValue,
                "companyName": this.state.location
            }
            Axios.post(Constants.BASE_URL + 'api/mediaManagement/renameFolder', data).then((result) => {
                if (result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Folder Name Updated Successfully!!",
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.props.getFileManagerFolderDetails(this.state.location)
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Unable to rename file. Please contact Admin!!",
                        showConfirmButton: false,
                        timer: 1000
                    })
                }
                this.setState({
                    isLoading: false
                })
            })
        })
    }

    uploadFiles = () => {
        var fileupload = document.getElementById("FileUpload1")
        this.setState({
            file: null
        }, () => {
            fileupload.click()
        })
    }

    fileupload = (e) => {
        this.setState({
            file: e.target.files
        }, () => {
            document.getElementById("submitFilesbtn").click()
        })
    }

    submitFiles = (e) => {
        e.preventDefault()
        this.setState({
            isLoading: true
        }, () => {
            var files = this.state.file
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            for (var i = 0; i < files.length; i++) {
                const formData = new FormData();
                formData.append("location", this.state.location)
                formData.append("fileUpload", files[i])
                formData.append("userId", JSON.parse(localStorage.getItem("userDetails"))[0].UserID)

                Axios.post(Constants.BASE_URL + "api/mediaManagement/uploadFiles", formData, config).then((result) => {
                    if (result.data.status) {
                        this.setState({
                            file: null,
                            isLoading: false
                        })

                        this.props.getFileManagerFolderDetails(this.state.location)
                    } else {
                        alert("Failure")
                        this.setState({
                            file: null,
                            isLoading: false
                        })
                    }
                });
            }
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: "Files Uploaded Sucessfully",
                showConfirmButton: false,
                timer: 1000
            })
        })
    }

    selectedFiles = (id, type) => {
        var array = [...this.state.selectedFiles];
        var obj = {
            id: id,
            type: type
        }
        var index = array.findIndex(x => x.id === id)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({
                selectedFiles: array
            });
        } else {
            array.push(obj);
            this.setState({
                selectedFiles: array
            });
        }
    }

    deleteFiles = () => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            text: "You won't be able to revert this!",
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    isLoading: true
                }, () => {
                    let data = {
                        files: this.state.selectedFiles,
                        location: this.state.location,
                        userId : JSON.parse(localStorage.getItem("userDetails"))[0].UserID
                    }
                    Axios.post(Constants.BASE_URL + "api/mediaManagement/deleteMedia", data).then((result) => {
                        if (result.data.status) {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: "Files Deleted Successfully",
                                showConfirmButton: false,
                                timer: 1000
                            })
                            this.setState({
                                isLoading: false,
                                selectedFiles: []
                            })
                            this.props.history.push(Constants.FileManager)
                            this.props.getFileManagerFolderDetails(this.state.location)
                        } else {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: "Unable to delete Files. Please try again!!",
                                showConfirmButton: false,
                                timer: 1000
                            })
                            this.setState({
                                isLoading: false,
                                selectedFiles: []
                            })
                        }
                    });
                })
            }
        })
    }

    downloadFile = (name, type) => {
        this.setState({
            isLoading: true
        }, () => {
            let data = {
                name: name,
                location: this.state.breadCrumb
            }
            Axios.post(Constants.BASE_URL + "api/mediaManagement/downloadMedia", data, {
                responseType: 'blob',
            })
                .then((res) => {
                    if (type === "Folder") {
                        fileDownload(res.data, name + ".zip")
                        Axios.post(Constants.BASE_URL + "api/mediaManagement/deleteZipFolderMedia", data)
                            .then((response) => {
                                if (response.status) {
                                    this.setState({
                                        isLoading: false
                                    }, () => {
                                        Swal.fire({
                                            position: 'top-end',
                                            icon: 'success',
                                            title: "File Downloaded Sucessfully",
                                            showConfirmButton: false,
                                            timer: 1000
                                        })
                                    })
                                } else {
                                    Swal.fire({
                                        position: 'top-end',
                                        icon: 'success',
                                        title: "Error in Downloading the File. Contact Admin!!",
                                        showConfirmButton: false,
                                        timer: 1000
                                    })
                                }

                            })
                    } else {
                        fileDownload(res.data, name)
                    }
                })

        })
    }

    previewFile = (name) => {
        let obj = {
            location: this.state.location,
            file: name
        }
        this.props.history.push(Constants.PreviewFile + "/[" + JSON.stringify(obj) + "]")
    }

    render() {
        return (
            <div className="fileManager" >
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div div className="row" >
                    <div className="col-12 activityLogDiv">
                        <p className="ActivityLogTitle pt-3 ">File Management</p>
                        <div className="actionDiv"></div>
                        <hr className="mt-0" />
                    </div>
                </div>
                <div className="row m-0 mt-2">
                    <div className="col-12 m-0 ">
                        <div className="row ">
                            <div className="col-12">
                                <label className="companyDetails ">
                                    <span className="selectdiv">Company:
                                        <select onChange={this.changeCompany} value={this.state.companyName} placeholder="Select Company">
                                            {this.state.CompanyList.length > 0 ? (this.state.CompanyList.map((company) => {
                                                return <option key={company.CompanyId} id={company.CompanyId} value={company.CompanyName}>{company.CompanyName}</option>
                                            })
                                            )
                                                : null}
                                        </select>
                                    </span>
                                    <span className="searchDiv">
                                        <i className="fa fa-search" aria-hidden="true"> </i><span>|</span>
                                        <input type="text" placeholder="Search keyword" onChange={(e) => this.props.searchMedia(e.target.value)} />
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row fileslistNavBar  filesList">
                    <div className="col-12 whiteBacground">
                        <div className="row">
                            <div className="col-4 text-left">
                                <label className="backbutton" onClick={this.navback}> <span><img src={dropdownImg} alt="backbutton" /></span>BACK  {(this.state.breadCrumb !== "" && this.state.breadCrumb !== null) ? <span>( {this.state.breadCrumb})</span> : null} </label>
                            </div>
                            <div className="col-8">
                                <label className="uploadNewFiles"><span><img src={uploadIcon} alt="uploadNewFiles" /></span><button onClick={this.uploadFiles}>Upload New Files</button></label>
                                <label className="uploadNewFiles addNewFolder"><span><img src={uploadNewFolder} alt="addFolder" /></span><button onClick={this.addNewFolder}>Add New Folder</button></label>
                                {this.state.selectedFiles.length > 0 ? (<label className="uploadNewFiles deleteFiles"><span><img src={trashCan} alt="DeleteFiles" /></span><button onClick={this.deleteFiles}>Delete Files</button></label>) : null}
                                <form id="uploadFiles" onSubmit={this.submitFiles}>
                                    <input type="file" onChange={this.fileupload} id="FileUpload1" multiple style={{ display: 'none' }} />
                                    <input type="button" id="submitFilesbtn" onClick={this.submitFiles} style={{ display: 'none' }} />
                                </form>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="filesListTable">
                                    {this.props.details.filteredFiles.length > 0 ?
                                        <DataTable
                                            columns={this.state.columns}
                                            data={this.props.details.filteredFiles}
                                            progressPending={this.props.details.filteredFiles.length > 0 ? false : true}
                                            pagination={true}
                                        /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default FileManager