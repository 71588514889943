import React from 'react'
import Axios from 'axios';
import LoaderComp from '../../Loader'
import * as Constants from '../../../assets/helpers/constants'
import '../../../assets/css/LicenseManagement.css'
import leftArrow from '../../../assets/images/Untitled-1-10.png'
import customerDown from '../../../assets/images/CustomerDown.png'
import Swal from 'sweetalert2'

class AdminCompanysLicenseIssue extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: false,
            companyId: null,
            companyDetails: [],
            composerLicenses: "",
            playerLicenses: ""
        }
    }
    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            const companyId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
            this.setState({
                companyId: companyId
            }, () => {
                this.getCompanyDetails(companyId)
            })
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getCompanyDetails = (companyId) => {
        this.setState({
            isLoading: true
        }, () => {
            Axios.get(Constants.BASE_URL + `api/AdminDashboard/getCompanyDetails/${companyId}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        companyId: companyId,
                        companyDetails: result.data.info,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        companyId: companyId,
                        isLoading: false
                    })
                }
            });
        })
    }

    setValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    issueLicenses = () => {
        if (this.state.composerLicenses === "" && this.state.playerLicenses === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Composer Licenses and Player Licenses cannot be empty',
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }
       
        this.setState({
            isLoading: true
        }, () => {
            let data = {
                composerLicenses: this.state.composerLicenses,
                playerLicenses: this.state.playerLicenses,
                companyId: this.state.companyId,
                createdBy: this.props.UserInfo.UserID
            }
            Axios.post(Constants.BASE_URL + `api/AdminDashboard/issueCompanyLicenses`, data).then((result) => {
                if (result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: result.data.info,
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.getQuickStats()
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Something went wrong.Licences cannot be created.',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.getQuickStats()
                    })
                }
            });
        })

    }

    navigate = (link) => {
        this.props.history.push(link + "/" + this.state.companyId)
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}

                <div className="pl-5 pr-5 reports companysSoftwareReleases">
                    <div className="row userTitle">
                        <div className="col-12 pb-2">
                            <label className="backButton" onClick={() => this.navigate(Constants.AdminCompanyDetails)}><img src={leftArrow} alt="leftArrow" /> <span> BACK </span></label>
                        </div>
                        <div className="col-12">
                            <p className="m-0 userlistlabel">Issue License/s</p>
                            <p className="m-0 userlistSublabel">Choose the version to release an update</p>
                        </div>
                    </div>

                    <div className="row companyDetailsSection">
                        <div className="col-2 customerIcon">
                            <img src={customerDown} alt="customerDown" />
                        </div>
                        <div className="col-10 p-0">
                            <div>
                                <label className='w-100 companyDetailsDiv ml-2'>
                                    <span className="adminCompanyName">{this.state.companyDetails.length > 0 ? this.state.companyDetails[0].Name : null}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row versionSelectionDiv">
                        <div className="col-5 ">
                            <p className="selectTitle m-0">Composer</p>
                            <p className="selectSubTitle">License/s</p>
                        </div>
                        <div className="col-7 ">
                            <input type="number" min={0} name="composerLicense" value={this.state.composerLicenses} placeholder="0" onChange={(e) => this.setValue("composerLicenses", e.target.value)} />
                        </div>
                    </div>

                    <div className="row versionSelectionDiv">
                        <div className="col-5">
                            <p className="selectTitle m-0">Player</p>
                            <p className="selectSubTitle">License/s</p>
                        </div>
                        <div className="col-7 ">
                            <input type="number" min={0} name="playerLicense" value={this.state.playerLicenses} placeholder="0" onChange={(e) => this.setValue("playerLicenses", e.target.value)} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col p-0">
                            <button type="button" className="shareUpdateBtn" onClick={this.issueLicenses}>Issue</button>
                        </div>
                    </div>

                    <hr className="footerDivHR" />
                    <div className="row helpSection footerDiv">
                        <div className="col-12 text-center">
                            <p className="footerSection">CMS Managed by Baetho, All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminCompanysLicenseIssue