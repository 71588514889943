import React from 'react';
import '../../../assets/css/Settings.css'
import Axios from 'axios'
import * as Constants from '../../../assets/helpers/constants'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import AddButton from '../../../assets/images/AddUp.png'
import userIcon from '../../../assets/images/ProfileIcon.png'
import Swal from 'sweetalert2'
import LoaderComp from '../../Loader'

class UsersList extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: false,
            hasEmailSent: false,
            defaultCompany: "SelectCompany",
            CompanyList: [],
            usersList: [],
            companyId: "",
            userId: null,
            showUsersScreen: true,
            showUserRegistration: false,
            showCreateNewCompany: false,
            showCompleteUserRegistration: false,
            companyOptions: [],
            rolesOptions: [],
            password: "",
            confirmPass: "",
            firstName: "",
            lastName: "",
            userName: "",
            email: "",
            companyName: "",
            userRole: "",
            selectedUserRole: ""
        }
    }

    componentDidMount() {
        let CompanyId = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyId
        let userId = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
        let Role = JSON.parse(localStorage.getItem("userDetails"))[0].Role
        this.setState({
            userRole: Role,
            companyName: Role === Constants.ContentManager ? CompanyId : "",
            selectedUserRole: Role === Constants.ContentManager ? 1 : "",
        }, () => {
            this.getCompanyDetails(CompanyId, userId)
            this.getUserDetails(CompanyId)
            this.getRoles(CompanyId)
        })

    }

    setFormData = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    // React Select Functions
    handleRoleChange = (newValue, actionMeta) => {
        if (newValue != null) {
            this.setState({
                "selectedUserRole": newValue.value
            })
        } else {
            this.setState({
                "selectedUserRole": ""
            })
        }
    }

    handleCompanyChange = (newValue, actionMeta) => {
        if (newValue != null) {
            this.setState({
                "companyName": newValue.value
            })
        } else {
            this.setState({
                "companyName": ""
            })
        }
    }

    getCompanyDetails = (CompanyId, userId) => {
        this.setState({
            isLoading: true
        }, () => {
            let CompanyName = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyName
            let companiesListOptions = []
            let role = JSON.parse(localStorage.getItem("userDetails"))[0].Role
            if (role === Constants.ContentManager) {
                let obj = {
                    value: CompanyId,
                    label: CompanyName
                }
                companiesListOptions.push(obj)
                this.setState({
                    CompanyList: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                    companyId: CompanyId,
                    userId: userId,
                    companyOptions: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                    isLoading: false
                })
            } else {
                Axios.get(Constants.BASE_URL + `api/Settings/getAllCompaniesOfUser/${userId}/${CompanyId}`).then((result) => {
                    if (result.data.status) {
                        result.data.info.forEach(element => {
                            let obj = {
                                value: element.Id,
                                label: element.Name
                            }
                            companiesListOptions.push(obj)
                        });
                        this.setState({
                            CompanyList: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                            companyId: CompanyId,
                            userId: userId,
                            companyOptions: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                            isLoading: false
                        })
                    } else {
                        this.setState({
                            CompanyList: [],
                            companyId: CompanyId,
                            userId: userId,
                            companyOptions: companiesListOptions.filter((ele, ind) => ind === companiesListOptions.findIndex(elem => elem.value === ele.value)),
                            isLoading: false

                        })
                    }
                })

            }

        })
    }

    getUserDetails = (CompanyId) => {
        let role = JSON.parse(localStorage.getItem("userDetails"))[0].Role
        Axios.get(Constants.BASE_URL + `api/Settings/getAllUserOfCompany/${CompanyId}/${role}`).then((result) => {
            if (result.data.status) {
                this.setState({
                    usersList: result.data.info,
                    companyId: CompanyId,
                })
            } else {
                this.setState({
                    usersList: [],
                    companyId: CompanyId,
                })
            }
        })
    }

    getRoles = (CompanyId) => {
        let userRole = JSON.parse(localStorage.getItem("userDetails"))[0].Role
        let roles = []
        let rolesListOptions = []
        if (userRole === Constants.ContentManager) {
            roles = [
                {
                    Id: 1,
                    Name: "ContentManager"
                }
            ]
        } else if (userRole === Constants.Reseller) {
            roles = [
                {
                    Id: 1,
                    Name: "ContentManager"
                },
                {
                    Id: 2,
                    Name: "Reseller"
                },
                {
                    Id: 4,
                    Name: "ComposerUser"
                },
            ]
        }else if(userRole === Constants.Admin){
            roles = [
                {
                    Id: 1,
                    Name: "ContentManager"
                },
                {
                    Id: 2,
                    Name: "Reseller"
                },
                {
                    Id: 3,
                    Name: "Admin"
                },
                {
                    Id: 4,
                    Name: "ComposerUser"
                },
            ]
        }

        roles.forEach(element => {
            let obj = {
                value: element.Id,
                label: element.Name
            }
            rolesListOptions.push(obj)
        });

        this.setState({
            companyId: CompanyId,
            rolesOptions: rolesListOptions
        })

        // Axios.get(Constants.BASE_URL + `api/Settings/getAllRolesOfCompany/${CompanyId}`).then((result) => {
        //     if (result.data.status) {
        //         let rolesListOptions = []

        //         result.data.info.forEach(element => {
        //             let obj = {
        //                 value: element.Id,
        //                 label: element.Name
        //             }
        //             rolesListOptions.push(obj)
        //         });

        //         this.setState({
        //             companyId: CompanyId,
        //             rolesOptions: rolesListOptions
        //         })
        //     } else {
        //         this.setState({
        //             companyId: CompanyId,
        //             rolesOptions: []
        //         })
        //     }
        // })
    }

    setValue = (key) => {
        let values = ['showUsersScreen', 'showUserRegistration', 'showCreateNewCompany', 'showCompleteUserRegistration']
        let valueObj = {}
        values.forEach(element => {
            if (key === element) {
                valueObj[element] = true
            } else {
                valueObj[element] = false
            }

        });
        this.setState(valueObj)
    }

    deleteUser = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete user?',
            text: "You won't be able to revert this!",
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    isLoading: true
                }, () => {
                    Axios.delete(Constants.BASE_URL + `api/deleteUser/${id}`).then((result) => {
                        if (result.data.status) {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: "User deleted successfully",
                                showConfirmButton: false,
                                timer: 1000
                            })
                            this.getUserDetails(this.state.companyId)
                        } else {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'Unable to delete user.Please contact Admin',
                                showConfirmButton: false,
                                timer: 1000
                            })
                        }
                    })
                    this.setState({
                        isLoading: false
                    })
                })

            }
        })

    }

    confirmNewCompany = () => {
        this.createNewUser()
    }

    loginPage = () => {
        this.props.history.push(Constants.Login)
    }

    validateForm = (event) => {
        event.preventDefault();

        if (this.state.firstName === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "First Name field is mandatory",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.lastName === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Last Name field is mandatory",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.userName === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "User Name field is mandatory",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }
        if (this.state.email === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Email field is mandatory",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.password === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "password field is mandatory",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.password !== this.state.confirmPass) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: " Passwords are not matching. Please Enter both password and confirm password the same",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if(this.state.userRole === Constants.ContentManager){
            if (Number.isInteger(this.state.companyName) || this.state.companyName === "") {
                this.createNewUser()
            } else {
                this.setValue("showCreateNewCompany")
            }
        }else{
            if(this.state.companyName === ""){
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Please select the company for the user",
                    showConfirmButton: false,
                    timer: 1000
                })
                return false
            }

            if(this.state.selectedUserRole === ""){
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Please select the role for the user",
                    showConfirmButton: false,
                    timer: 1000
                })
                return false
            }
            if (Number.isInteger(this.state.companyName)) {
                this.createNewUser()
            } else {
                this.setValue("showCreateNewCompany")
            }
        }
        return false
    }

    createNewUser = () => {
        this.setState({
            isLoading: true
        }, () => {
            let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
            let data = {
                password: this.state.password,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                userName: this.state.userName,
                email: this.state.email,
                companyName: this.state.companyName,
                userRole: this.state.selectedUserRole,
                userId: userID
            }

            Axios.post(Constants.BASE_URL + `api/CreateUser`, data).then((result) => {
                if (result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: " User Created Successfully",
                        showConfirmButton: false,
                        timer: 1000
                    })

                    this.setState({
                        password: "",
                        confirmPass: "",
                        firstName: "",
                        lastName: "",
                        userName: "",
                        email: "",
                        companyName: "",
                        userRole: "",
                        isLoading: false
                    }, () => {
                        let CompanyId = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyId
                        let userId = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
                        this.getCompanyDetails(CompanyId, userId)
                        this.getUserDetails(CompanyId)
                        this.getRoles(CompanyId)
                        this.setValue("showCompleteUserRegistration")
                    })

                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: result.data.info,
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.setValue("showUserRegistration")
                    this.setState({
                        isLoading: false
                    })
                }
            })
        })

    }

    render() {
        return (
            <div className="rightSidePanel slide">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                {(this.state.showUsersScreen && !this.state.showUserRegistration && !this.state.showCreateNewCompany && !this.state.showCompleteUserRegistration) ?
                    (
                        <div className="pl-5 settingsUsersList">
                            <div className="row">
                                <div className="col-12">
                                    <label className="companyDetails">
                                        <span className="selectdiv"><p className="p-0 m-0 companyLable">Company:</p>
                                            <select onChange={(e) => this.getUserDetails(e.target.value)} value={this.state.companyId} placeholder="Select Company">
                                                {/* <option id="Select Company" disabled value="SelectCompany" > Select Company </option> */}
                                                {this.state.CompanyList.length > 0 ? (this.state.CompanyList.map((company) => {
                                                    return <option key={company.value} id={company.value} value={company.value}>{company.label}</option>
                                                })
                                                )
                                                    : null}
                                            </select>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="row userTitle">
                                <div className="col-6">
                                    <p className="m-0 userlistlabel">User List</p>
                                    <p className="m-0 userlistSublabel">Complete User List</p>
                                </div>
                                <div className="col-6">
                                    <div className="addUserButtion text-center">
                                        <span onClick={() => this.setValue("showUserRegistration")}>
                                            <img src={AddButton} alt="AddButton" />
                                            <p className="p-0 m-0 adduserText"> Add User </p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row indvUsersDiv">
                                {this.state.usersList.length > 0 ? (this.state.usersList.map((user, index) => {
                                    return (
                                        <div key={index} className="col-12">
                                            <div className="row indvUsers">
                                                <div className="col-2 ">
                                                    <img src={userIcon} alt="userIcon" className="userIcon" />
                                                </div>
                                                <div className="col-8">
                                                    <p className="username p-0 m-0">{user.UserName}</p>
                                                    <p className="userRole p-0 m-0">Role: {user.RoleName}</p>
                                                </div>
                                                <div className="col-2 margin-auto">
                                                    <button type="button" className="deleteUserBtn" onClick={() => this.deleteUser(user.UserId)}>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                ) : null}
                            </div>
                        </div>
                    )
                    : null}

                {(!this.state.showUsersScreen && this.state.showUserRegistration && !this.state.showCreateNewCompany && !this.state.showCompleteUserRegistration) ?
                    (<div className="pl-5 pr-5 userRegistration">
                        <div className="row userTitle">
                            <div className="col-12">
                                <p className="m-0 userlistlabel">User Registration</p>
                                <p className="m-0 userlistSublabel">Please complete to register your account.</p>
                            </div>
                        </div>

                        <form id="newUserForm" onSubmit={this.validateForm}>
                            <div className="row">
                                <div className="col-6">
                                    <input type="text" name="FirstName" placeholder="First Name" value={this.state.firstName} onChange={(e) => this.setFormData("firstName", e.target.value)} required />
                                </div>
                                <div className="col-6">
                                    <input type="text" name="LastName" placeholder="Last Name" value={this.state.lastName} onChange={(e) => this.setFormData("lastName", e.target.value)} required/>
                                </div>
                                <div className="col-12">
                                    <input type="text" name="UserName" placeholder="User Name" value={this.state.userName} onChange={(e) => this.setFormData("userName", e.target.value)} required />
                                </div>
                                <div className="col-12">
                                    <input type="email" name="Email" placeholder="Email" value={this.state.email} onChange={(e) => this.setFormData("email", e.target.value)} required/>
                                </div>
                                <div className="col-12">
                                    {this.state.userRole === Constants.ContentManager ?
                                        null
                                        : (<CreatableSelect
                                            isClearable
                                            onChange={this.handleCompanyChange}
                                            options={this.state.companyOptions}
                                            placeholder="Company Name"
                                        />)}
                                </div>
                                <div className="col-12">
                                    <input type="password" name="password" placeholder="Password" value={this.state.password} onChange={(e) => this.setFormData("password", e.target.value)}  required/>
                                </div>
                                <div className="col-12">
                                    <input type="password" name="confirmPassword" placeholder="Confirm Password" value={this.state.confirmPass} onChange={(e) => this.setFormData("confirmPass", e.target.value)} required/>
                                </div>
                                {this.state.userRole === Constants.ContentManager ? null :
                                    (<div className="col-12">
                                        <Select
                                            isClearable
                                            onChange={this.handleRoleChange}
                                            options={this.state.rolesOptions}
                                            placeholder="User Role"
                                        />
                                    </div>)}

                                <div className="col-12">
                                    <button type="submit" className="addUserSubmitBtn">Add User</button>
                                </div>
                            </div>
                        </form>
                        <div className="row">
                            <div className="col-12">
                                <p className="footerText">Term of use. Privacy policy</p>
                            </div>
                        </div>
                    </div>)
                    :
                    null}

                {(!this.state.showUsersScreen && !this.state.showUserRegistration && this.state.showCreateNewCompany && !this.state.showCompleteUserRegistration) ?
                    (
                        <div className="pl-5 pr-5 newCompanyConfirmation text-center">
                            <div className="row userTitle">
                                <div className="col-12">

                                    <p className="userlistlabel">You are creating <br /> a new company. <br />Do you want to Proceed?</p>
                                    <p className="userlistSublabel">Click to continue</p>
                                    <button type="button" className="proceedBtn" onClick={() => this.confirmNewCompany()}>Proceed</button>
                                    {/* <button type="button" className="proceedBtn" onClick={()=>this.setValue("showUserRegistration")}>No</button> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className="footerText">Term of use. Privacy policy</p>
                                </div>
                            </div>
                        </div>
                    ) :
                    null}

                {(!this.state.showUsersScreen && !this.state.showUserRegistration && !this.state.showCreateNewCompany && this.state.showCompleteUserRegistration) ?
                    (
                        <div className="pl-5 pr-5 CompleteUserRegistration text-center">
                            <div className="row userTitle">
                                <div className="col-12">
                                    <p className="userlistlabel">Done!</p>
                                    <p className="userlistSublabel">Please check your email.</p>
                                    <button type="button" className="loginBtn" onClick={() => this.setValue("showUsersScreen")}>Back</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className="footerText">Term of use. Privacy policy</p>
                                </div>
                            </div>
                        </div>
                    ) :
                    null}
            </div>
        )
    }
}

export default UsersList