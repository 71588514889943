import React from 'react';
import { Link } from "react-router-dom";
import * as Constants from '../../../assets/helpers/constants'
import '../../../assets/css/LeftSidePanel.css'
import logoImg from '../../../assets/images/logo.png';
import LogoutButton from '../../../assets/images/LogoutDown.png'
class LeftSidePanel extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoggedIn: false,
            activeLink: 'dashboard'
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.setState({
                isLoggedIn: true,
            })
        }
    }

    logOut = () => {
        localStorage.removeItem('userDetails')
        localStorage.removeItem('rememberme')
        this.props.history.push('/')
    }

    setActive = (id) => {
        this.props.checkPath(id)
    }

    render() {
        return (
            <div className="leftSidePanel text-center">
                <img src={logoImg} alt="logo" className="logoImg" />
                <div className="navBar">
                    <div className="navIcons">
                        <ul>
                            <li>
                                <Link to={Constants.MainDashboard} className={this.props.activeLink === 'dashboard' ? "disabled-link" : null}>
                                    <i id="dashboard" className={this.props.activeLink === 'dashboard' ? "fas fa-th-large navLinks active" : "fas fa-th-large navLinks"} onClick={() => { this.setActive("dashboard") }}></i>
                                </Link>
                            </li>
                            <li>
                                <Link to={Constants.ExperienceDashboard} className={this.props.activeLink === 'experience' ? "disabled-link" : null}>
                                    <i id="experience" className={this.props.activeLink === 'experience' ? "fas fa-people-carry navLinks active" : "fas fa-people-carry navLinks"} onClick={(e) => { this.setActive("experience") }}></i>
                                </Link>
                            </li>
                            <li>
                                <Link to={Constants.LicenseDashboard} className={this.props.activeLink === 'license' ? "disabled-link" : null}>
                                    <i id="license" className={this.props.activeLink === 'license' ? "fas fa-calendar-alt navLinks active" : "fas fa-calendar-alt navLinks"} onClick={(e) => { this.setActive("license") }}></i>
                                </Link>
                            </li>
                            <li>
                                <Link to={Constants.DeviceDashboard} className={this.props.activeLink === 'device' ? "disabled-link" : null}>
                                    <i id="device" className={this.props.activeLink === 'device' ? "fas fa-list-alt navLinks active" : "fas fa-list-alt navLinks"} onClick={(e) => { this.setActive("device") }}></i>
                                </Link>
                            </li>
                            <li>
                                <Link to={Constants.FileManager} className={this.props.activeLink === 'filemanager' ? "disabled-link" : null}>
                                    <i id="filemanager" className={this.props.activeLink === 'filemanager' ? "far fa-folder navLinks active" : "far fa-folder navLinks"} onClick={(e) => { this.setActive("filemanager") }}></i>
                                </Link>
                            </li>
                            <li>
                                <Link to={Constants.SettingsDashboard} className={this.props.activeLink === 'settings' ? "disabled-link" : null}>
                                    <i id="settings" className={this.props.activeLink === 'settings' ? "fas fa-cogs navLinks active" : "fas fa-cogs navLinks"} onClick={(e) => { this.setActive("settings") }}></i>
                                </Link>
                            </li>
                            {(this.props.UserInfo.Role === "Admin" || this.props.UserInfo.Role === "ComposerUser") ? (
                                <li>
                                    <Link to={Constants.AllPosts}>
                                        <i id="forum" className="fab fa-forumbee"></i>
                                    </Link>
                                </li>
                            ) : null}

                        </ul>
                    </div>
                    {this.state.isLoggedIn ? (<img src={LogoutButton} alt="logout" className="logoutButton" onClick={() => this.logOut()} />) : null}
                </div>

            </div>
        )
    }
}

export default LeftSidePanel