import React from 'react';
import * as Constants from '../../../assets/helpers/constants'
import '../../../assets/css/MainDashboard.css'
import '../../../assets/css/AdminDashboardCSS/Index.css'
// import Checkout from '../../../assets/helpers/checkout'

class AdminDashboard extends React.Component {
    constructor(){
        super()
        this.state = {
            activeLink : null
        }
    }
    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            let activeLink = ""
            if (this.props.location.pathname === Constants.AdminSoftwareReleasePanel ) {
                activeLink = 'softwareRelease'
            } else if (this.props.location.pathname === Constants.AdminUsageReportsPanel) {
                activeLink = 'usageReport'
            } else if(this.props.location.pathname === Constants.AdminUserManagementPanel){
                activeLink = 'userManagement'
            }

            this.setState({
                activeLink: activeLink
            })
            this.props.getQuickStats()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    navigate = (link, value) => {
        this.setState({
            activeLink : value
        },()=>{
            this.props.history.push(link)
        })
    }

    render() {
        return (
            <div className="mainDashboard adminDashboard">
                {/* <Checkout /> */}
                <div className="row">
                    <div className="col-12">
                        <p className="QuickStatsTitle pt-1">Quick Stats</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <div className="quickStats">
                            <p className="title ">Total Licenses Issued</p>
                            <p className="LicensesValue">{this.props.quickStats.totalLicenses}</p>
                        </div>
                    </div>
                    <div className="col text-center">
                        <div className="quickStats">
                            <p className="title">Renewal In 3 months</p>
                            <p className="LicensesValue">{this.props.quickStats.threeMonthsrenewal}</p>
                        </div>
                    </div>
                    <div className="col text-center">
                        <div className="quickStats">
                            <p className="title">Renewal In 6 months</p>
                            <p className="LicensesValue">{this.props.quickStats.sixMonthsrenewal}</p>
                        </div>
                    </div>
                    <div className="col text-center">
                        <div className="quickStats">
                            <p className="title ">Total Resellers</p>
                            <p className="LicensesValue">{this.props.quickStats.totalResellers}</p>
                        </div>
                    </div>
                    <div className="col text-center">
                        <div className="quickStats">
                            <p className="title">Total Customers</p>
                            <p className="LicensesValue">{this.props.quickStats.totalCustomers}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 activityLogDiv">
                        <p className="ActivityLogTitle pt-3 ">Menu</p>
                        <div className="actionDiv"></div>
                        <hr className="mt-0" />
                    </div>
                </div>
                <div className="row p-3 ">
                    <div className="col-12">
                        <div className="row AdminDashboardNavDiv">
                            <div className="col-3">
                                <div id="userManagement" className={this.state.activeLink === 'userManagement' ? "sectionBox activeBox" : "sectionBox"} onClick={(e) => this.navigate(Constants.AdminUserManagementPanel, "userManagement")}>
                                    <p className="settingType">Setting Type</p>
                                    <p className="settingName">Users Management</p>
                                </div>
                            </div>
                            <div className="col-3">
                                <div id="usageReport" className={this.state.activeLink === 'usageReport' ? "sectionBox activeBox" : "sectionBox"} onClick={(e) => this.navigate(Constants.AdminUsageReportsPanel, "usageReport")}>
                                    <p className="settingType">Setting Type</p>
                                    <p className="settingName">Usage Report</p>
                                </div>
                            </div>
                            <div className="col-3">
                                <div id="softwareRelease" className={this.state.activeLink === 'softwareRelease' ? "sectionBox activeBox" : "sectionBox"} onClick={(e) => this.navigate(Constants.AdminSoftwareReleasePanel, "softwareRelease")}>
                                    <p className="settingType">Setting Type</p>
                                    <p className="settingName">Software Release</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminDashboard
