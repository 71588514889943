import React from 'react';
import Axios from 'axios'
import '../../../assets/css/DeviceDashboard.css'
import * as Constants from '../../../assets/helpers/constants'
import DataTable from 'react-data-table-component';
import AndroidIcon from '../../../assets/images/AndroidUp.png'
import IOSIcon from '../../../assets/images/IOSUp.png'
import WindowsIcon from '../../../assets/images/WindowsUp.png'

class DeviceDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            defaultCompany: "Select Company",
            CompanyList: [],
            companyName: null,
            companyId: null,
            devicesList: null,
            assigned: 0,
            unassigned: 0,
            // selectedDevices: [],
            columns: [
                {
                    name: 'OS',
                    grow: 0,
                    center: 1,
                    cell: row => <div data-tag="allowRowEvents" className="osIcon text-center"> <img src={row.deviceOS === "IOS" ? IOSIcon : row.deviceOS === "Android" ? AndroidIcon : WindowsIcon} alt="OSIcon" />  </div>,
                    sortable: false
                },
                {
                    name: 'Device Name ',
                    grow: 2,
                    center: 1,
                    cell: row => <div data-tag="allowRowEvents" className="devicesName">{row.deviceName}</div>,
                    selector: 'deviceName',
                    sortable: true
                },
                {
                    name: 'Status',
                    grow: 0,
                    center: 1,
                    cell: row => <div data-tag="allowRowEvents" className="devicesStatus text-center">{row.deviceStatus === 1 ? "Active" : "InActive"}</div>,
                    selector: 'Is_Active',
                    sortable: true
                },

                {
                    name: 'Experience Type',
                    grow: 1,
                    center: 1,
                    cell: row => <div data-tag="allowRowEvents" className="devicesExpName text-center">{row.ExperienceName}</div>,
                    selector: 'ExperienceName',
                    sortable: true
                },
                {
                    name: 'Tags',
                    grow: 1,
                    center: 1,
                    cell: row => <div data-tag="allowRowEvents" className="devicesTagName text-center">{row.TagName}</div>,
                    selector: 'TagName',
                    sortable: true
                }
            ]
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            let CompanyName = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyName
            let CompanyId = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyId
            this.setState({
                companyId: CompanyId,
                companyName: CompanyName
            }, () => {
                this.getCompanyDetails()
            })
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getCompanyDetails = () => {
        this.setState({
            isLoading: true
        }, () => {
            let CompanyName = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyName
            let CompanyId = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyId
            let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID
            let role = JSON.parse(localStorage.getItem("userDetails"))[0].Role

            if (role === Constants.ContentManager) {
                this.setState({
                    CompanyList: [{
                        CompanyId: CompanyId,
                        CompanyName: CompanyName
                    }],
                    companyId: CompanyId,
                    companyName: CompanyName,
                    defaultCompany: CompanyName + "_" + CompanyId,
                    isLoading: false
                })
                this.props.getDevices(CompanyName, CompanyId)
            } else {
                Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getCompaniesList/${userID}/${CompanyId}`).then((result) => {
                    if (result.data.status) {
                        this.setState({
                            CompanyList: result.data.info,
                            companyId: CompanyId,
                            companyName: CompanyName,
                            defaultCompany: CompanyName + "_" + CompanyId,
                            isLoading: false
                        })
                        this.props.getDevices(CompanyName, CompanyId)
                    }
                })
            }

        })
    }

    getDevices = (value) => {
        var x = value.split("_")
        this.setState({
            defaultCompany: value,
            companyId: x[1],
            companyName: x[0]
        })
        this.props.getDevices(x[0], x[1])
    }

    render() {
        return (<div className="DeviceDashboard">
            <div className="row">
                <div className="col-12 activityLogDiv">
                    <p className="licenseCount pt-3 ">
                        <span>Active  - {this.props.activeDevices}</span>
                        <span>Not Active - {this.props.inactiveDevices}</span>
                    </p>
                    <div className="actionDiv"></div>
                    <hr className="mt-0" />
                </div>
            </div>
            <div className="row ">
                <div className="col-12">
                    <label className="companyDetails">
                        <span className="selectdiv">Company:
                            <select onChange={(e) => this.getDevices(e.target.value)} value={this.state.defaultCompany} placeholder="Select Company"> 
                                {this.state.CompanyList.length > 0 ? (this.state.CompanyList.map((company) => {
                                    return <option key={company.CompanyId} id={company.CompanyId} value={company.CompanyName + "_" + company.CompanyId}>{company.CompanyName}</option>
                                })
                                )
                                    : null}
                            </select>
                        </span>
                    </label>
                </div>
            </div>
            <div className="row devicesRow">
                <div className="col-12">
                    <div className="devicesDiv">
                        {this.props.devicesList.length > 0 ?
                            <DataTable
                                columns={this.state.columns}
                                selectableRows={true}
                                data={this.props.devicesList}
                                progressPending={this.props.devicesList.length > 0 ? false : true}
                                onSelectedRowsChange={this.props.selectDevices}
                                pagination={true}
                            /> : null
                        }
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default DeviceDashboard