import React from 'react';
import '../../../assets/css/LicenseManagement.css'
import Axios from 'axios'
import LoaderComp from '../../Loader'
import * as Constants from '../../../assets/helpers/constants'
import copyImg from '../../../assets/images/DuplicateDown.png'
import DataTable from 'react-data-table-component';
import Moment from 'react-moment';

class LicenseDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            defaultCompany: "",
            Experiences: [],
            CompanyList: [],
            licensesList: props.licenceInfo.licensesList,
            filteredLicenses: props.licenceInfo.licensesList,
            companyId: this.props.licenceInfo.companyId,
            companyName: "",
            assigned: props.licenceInfo.assigned,
            unassigned: props.licenceInfo.unassigned,
            expired: props.licenceInfo.expired,
            columns: [
                {
                    name: 'Issued On',
                    cell: row => <div data-tag="allowRowEvents"><Moment format="DD-MMM-YYYY">
                        {row.Created_On}
                    </Moment></div>,
                    selector: 'Created_On',
                    sortable: true,
                },
                {
                    name: 'License Key #',
                    cell: row => <div data-tag="allowRowEvents" className="licenceKeyDiv" title="Click To Copy Licence Key">
                        <span className="key">{row.licenseKey}</span>
                        <span onClick={() => this.copyLicenseKey(row.licenseKey)} className="copyImg">
                            <img src={copyImg} alt="copyImg" />
                        </span>
                        <span className="keySeperator">|</span>

                    </div>,
                    selector: 'licenseKey',
                    sortable: true
                },
                {
                    name: 'Status',
                    cell: row => <div data-tag="allowRowEvents" >{row.Is_Active === 1 ? "Active" : "InActive"}</div>,
                    selector: 'Is_Active',
                    sortable: true
                },
                {
                    name: 'Assigned On',
                    cell: row => <div data-tag="allowRowEvents">
                        {row.Start_Date != null ? (
                            <Moment format="DD-MMM-YYYY">
                                {row.Start_Date}
                            </Moment>)
                            : null}
                    </div>,
                    selector: 'Start_Date',
                    sortable: true
                },
                {
                    name: 'Expire Date',
                    cell: row => <div data-tag="allowRowEvents">
                        {row.Expiry_Date != null ? (
                            <Moment format="DD-MMM-YYYY">
                                {row.Expiry_Date}
                            </Moment>)
                            : null}
                    </div>,
                    selector: 'Expiry_Date',
                    sortable: true
                },
            ]
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getCompanyDetails()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    copyLicenseKey = (value) => {
        navigator.clipboard.writeText(value)
    }

    getCompanyDetails = () => {
        this.setState({
            isLoading: true
        }, () => {
            let CompanyName = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyName
            let CompanyId = JSON.parse(localStorage.getItem("userDetails"))[0].CompanyId
            let userID = JSON.parse(localStorage.getItem("userDetails"))[0].UserID

            let role = JSON.parse(localStorage.getItem("userDetails"))[0].Role
            if (role === Constants.ContentManager) {
                this.setState({
                    CompanyList: [{
                        CompanyId: CompanyId,
                        CompanyName: CompanyName
                    }],
                    companyId: CompanyId,
                    companyName: CompanyName,
                    defaultCompany: CompanyName + "_" + CompanyId,
                    isLoading: false
                })
                this.props.getLicenses(CompanyName + "_" + CompanyId)
            } else {
                Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getCompaniesList/${userID}/${CompanyId}`).then((result) => {
                    if (result.data.status) {
                        this.setState({
                            CompanyList: result.data.info,
                            companyId: CompanyId,
                            companyName: CompanyName,
                            defaultCompany: CompanyName + "_" + CompanyId,
                            isLoading: false
                        })
                        this.props.getLicenses(CompanyName + "_" + CompanyId)
                    }
                })
            }

        })
    }

    render() {
        return (
            <div className="LicenseDashboard">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="row">
                    <div className="col-12 activityLogDiv">
                        <p className="licenseCount pt-3 ">
                            <span>Assigned - {this.props.licenceInfo.assigned}</span>
                            <span>Unassigned - {this.props.licenceInfo.unassigned}</span>
                            <span>Expired - {this.props.licenceInfo.expired}</span></p>
                        <div className="actionDiv"></div>
                        <hr className="mt-0" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <label className="companyDetails">
                            <span className="selectdiv">Company:
                                <select onChange={(e) => this.props.getLicenses(e.target.value)} value={this.props.licenceInfo.defaultCompany !== null ? this.props.licenceInfo.defaultCompany : ""} placeholder="Select Company">
                                    {/* <option id="Select Company" disabled > Select Company </option> */}
                                    {this.state.CompanyList.length > 0 ? (this.state.CompanyList.map((company) => {
                                        return <option key={company.CompanyId} id={company.CompanyId} value={company.CompanyName + "_" + company.CompanyId}>{company.CompanyName}</option>
                                    })
                                    )
                                        : null}
                                </select>
                            </span>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="type" checked={this.props.licenceInfo.isComposer} onChange={() => this.props.changeLicenses("Composer")} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Composer</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="type" checked={this.props.licenceInfo.isPlayer} onChange={() => this.props.changeLicenses("Player")} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Player</label>
                            </div>
                        </label>
                    </div>
                </div>
                <div className="row licensesDiv">
                    <div className="col-12">
                        {(this.props.licenceInfo.licensesList != null && this.props.licenceInfo.filteredLicenses.length > 0) ?
                            <DataTable
                                columns={this.state.columns}
                                data={this.props.licenceInfo.filteredLicenses}
                                selectableRows={true}
                                progressPending={this.props.licenceInfo.licensesList != null ? false : true}
                                pagination={true}
                                onSelectedRowsChange={this.props.selectedLicense}
                            /> : null
                        }
                    </div>
                </div>
            </div>)
    }
}

export default LicenseDashboard