import React from 'react';
import Axios from 'axios';
import * as Constants from '../../../assets/helpers/constants'
import addUp from '../../../assets/images/AddUp.png'
import customerDown from '../../../assets/images/CustomerDown.png'

class AdminUserManagement extends React.Component {
    constructor() {
        super()
        this.state = {
            resellerList: [],
            userCounts :null
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            this.getResellersList()
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    getResellersList = () => {
        Axios.get(Constants.BASE_URL + `api/AdminDashboard/getAllResellers`).then((result) => {
            let counts = result.data.info.totalUsers.reduce((acc, curr)=>{
                const str = JSON.stringify(curr.CompanyName);
                acc[str] = (acc[str] || 0) + 1;
                return acc;
             }, {});

            if (result.data.status) {
                this.setState({
                    resellerList: result.data.info.reSellersList.filter( (ele, ind) => ind === result.data.info.reSellersList.findIndex( elem => elem.CompanyName === ele.CompanyName)),
                    userCounts :counts
                })
            }
        });
    }

    addUser = () => {
        this.props.history.push(Constants.AdminUserCreation)
    }
    navigate = (id) => {
        this.props.history.push(Constants.AdminCompanyDetails+"/"+id)
    }
    render() {
        return (
            <div className="rightSidePanel slide">
                <div className="pl-5 pr-5 reports adminUserManagement">
                    <div className="row">
                        <div className="col-12 p-0">
                            <label className="searchDiv"><i className="fa fa-search" aria-hidden="true"> </i><span>|</span>
                                <input type="text" placeholder="Search keyword" />
                            </label>
                        </div>
                    </div>
                    <div className="row userTitle resellerListDiv">
                        <div className="col-8">
                            <p className="m-0 userlistlabel">Reseller List</p>
                            <p className=" userlistSublabel">Complete User List</p>
                        </div>
                        <div className="col-4 p-0">
                            <div className="AddUserAdmin">
                                <label className="addUserAdmin" onClick={this.addUser}>
                                    <img src={addUp} alt="addUp" />
                                    <span className="addUserbtn">Add User</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 usersListResellers">
                            {this.state.resellerList.length > 0 ?
                                (
                                    this.state.resellerList.map((reseller,index) => {
                                        let companyName = JSON.stringify(reseller.CompanyName)
                                        const companyList = this.state.userCounts
                                        let users =  companyList[companyName]
                                        return (
                                            <div key={index} className="row individualReatilSeller">
                                                <div className="col-2 customerDownImg">
                                                    <img src={customerDown} alt="customerDown" />
                                                </div>
                                                <div className="col-7 companyDetailsReseller">
                                                    <p className="companyName">{reseller.CompanyName}</p>
                                                    <p className="usersDetails">{users} - Users</p>
                                                </div>
                                                <div className="col-3">
                                                    <p className="viewBtn" onClick={()=>this.navigate(reseller.CompanyId)}>View</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                                : (
                                    <div>No Resellers Found</div>
                                )
                            }

                        </div>
                    </div>
                    <hr className="footerDivHR" />
                    <div className="row helpSection footerDiv">
                        <div className="col-12 text-center">
                            <p className="footerSection">CMS Managed by Baetho, All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminUserManagement