import React from 'react';
import Axios from 'axios';
import LoaderComp from '../../Loader'
import * as Constants from '../../../assets/helpers/constants'
import SidePanelFooter from './sidepanelFooter'
import UploadImg from '../../../assets/images/Upload.png'
import Swal from 'sweetalert2'

class AddExperience extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            defaultValueExperienceStatus: "Experience Status",
            companyId: null,
            experienceName: "",
            file: null,
            tagId: "Tag Details",
            tagsList: [],
            CompanyName: ""
        }
    }

    componentDidMount() {
        if (this.props.UserInfo !== undefined) {
            const companyId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
            Axios.get(Constants.BASE_URL + `api/getCompanyDetails/${companyId}`).then((result) => {
                if (result.data.status && result.data.info.length > 0) {
                    this.setState({
                        CompanyName: result.data.info[0].Name
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Cannot fetch Company Details",
                        showConfirmButton: false,
                        timer: 1000
                    })
                }
            });

            Axios.get(Constants.BASE_URL + `api/ExperienceManagement/getTagsList/${companyId}`).then((result) => {
                if (result.data.status) {
                    this.setState({
                        tagsList: result.data.info,
                        companyId: companyId
                    })
                }
            });
        } else {
            this.props.history.push(Constants.Login)
        }
    }

    addExperience = (event) => {
        event.preventDefault();

        if (!/[^a-zA-Z0-9]/.test(this.state.experienceName)) {

        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Experience Name Should not contain spaces or Special Characters",
                showConfirmButton: false,
                timer: 2000
            })
            return false
        }
      
        if (this.state.defaultValueExperienceStatus === "Experience Status") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please select Experience Status ",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        if (this.state.file === null) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please upload Experience File ",
                showConfirmButton: false,
                timer: 1000
            })
            return false
        }

        this.setState({
            isLoading: true
        }, () => {
            const formData = new FormData();
            formData.append('fileUpload', this.state.file);
            formData.append('expStatus', this.state.defaultValueExperienceStatus);
            formData.append('tagId', this.state.tagId);
            formData.append('ExpName', this.state.experienceName);
            formData.append('companyId', this.state.companyId);
            formData.append('userId', JSON.parse(localStorage.getItem("userDetails"))[0].UserID !== null ? JSON.parse(localStorage.getItem("userDetails"))[0].UserID : 0);
            formData.append('companyName', this.state.CompanyName)

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            Axios.post(Constants.BASE_URL + "api/ExperienceManagement/createExperience", formData, config).then((result) => {
                if (result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Experience Created Successfully!!",
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.getExperiences(this.props.companyName + "_" + this.state.companyId)
                        this.props.history.push(Constants.ExperienceDashboard);
                    })

                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: result.data.info,
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.setState({
                        isLoading: false
                    })
                }
            });

        })

        return false
    }

    changeValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    changeFile = (e) => {
        var fileName = e.target.value
        var extension = fileName.split('.').pop();
        if (extension !== 'exp') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please upload only .exp files",
                showConfirmButton: false,
                timer: 1000
            })
        } else {
            this.setState({ file: e.target.files[0] })
        }
    }

    render() {
        return (
            <div className="rightSidePanel slide">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="signin pl-5 addExperience">
                    <p className="loginTitle mb-0">Add New Experience</p>
                    <p className="addExpSubTitle">Configure your experience below</p>
                    <form id="addExperience" onSubmit={this.addExperience} >
                        <input type="text" name="experienceName" className="w-100 expName" placeholder="Experience Name" maxlength="40" onChange={(e) => this.changeValue("experienceName", e.target.value)} required />
                        <select value={this.state.defaultValueExperienceStatus} name="experienceStatus" onChange={(e) => this.changeValue("defaultValueExperienceStatus", e.target.value)} required>
                            <option disabled value="Experience Status">Experience Status</option>
                            <option value="1">Active</option>
                            <option value="0">InActive</option>
                        </select>
                        <select value={this.state.tagId} name="tagdetails" onChange={(e) => this.changeValue("tagId", e.target.value)} >
                            <option disabled value="Tag Details">Tag Details</option>
                            {this.state.tagsList.map((tag, index) => {
                                return <option key={index} value={tag.Id}>{tag.Name}</option>
                            })}
                        </select>
                        <div className="fileDiv">
                            <label className="w-100">
                                <input type="file" name="expFile" accept="*.exp" placeholder="Upload Experience File" onChange={this.changeFile} required />
                                <span>
                                    <img src={UploadImg} alt="UploadIcon" />
                                </span>
                            </label>
                        </div>
                        <button type="submit" className="uploadExperienceBtn">Upload Experience </button>
                    </form>
                    <SidePanelFooter />
                </div>
            </div>
        )
    }
}

export default AddExperience