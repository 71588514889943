import React from 'react';
import Axios from 'axios';
import * as Constants from '../../../assets/helpers/constants'
import '../../../assets/css/ExperienceDashboard.css'

import jpgIcon from '../../../assets/images/JPGUp.png'
import pngIcon from '../../../assets/images/PNGUp.png'
import pdfIcon from '../../../assets/images/PDFUp.png'
import xmlIcon from '../../../assets/images/XMLUp.png'
import folderIcon from '../../../assets/images/folder.png'
// import uploadIcon from '../../../assets/images/UploadUp.png'
import dropdownImg from '../../../assets/images/Untitled-1-10.png'
// import newFolderImg from '../../../assets/images/folder_Plus-78.png'


class MediaManagement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLinkPanelOpen: false,
            companyName: null,
            experienceName: null,
            location: null,
            files: [],
            jpeg: 0,
            png: 0,
            pdf: 0,
            xml: 0,
            jpegFiles: [],
            pngFiles: [],
            pdfFiles: [],
            xmlFiles: [],
            foldersFiles: [],
            otherFormats: [],
            breadCrumb: null
        }
    }

    componentDidMount() {
        let url = this.props.location.pathname.split('/')
        let startURL = url.splice(3);
        let endUrl = ""

        if (startURL.includes('file') || startURL.includes('folder')) {
            endUrl = startURL.splice(0, startURL.length - 2)
        } else {
            endUrl = startURL
        }


        if (url.length > 2) {
            const ExpName = endUrl[1]
            const CompanyName = endUrl[0]
            if (CompanyName !== "folder" && CompanyName !== "file") {
                let data = {
                    "location": endUrl.join("/")
                }
                Axios.post(Constants.BASE_URL + 'api/mediaManagement/getMediaofCompany', data).then((result) => {
                    if (result.data.status) {
                        this.setState({
                            location: CompanyName + "/" + ExpName,
                            companyName: CompanyName,
                            experienceName: ExpName
                        })
                        this.props.getFolderDetails(CompanyName + "/" + ExpName)
                    }
                })
            } else {
                this.setState({
                    location: CompanyName + "/" + ExpName,
                    companyName: CompanyName,
                    experienceName: ExpName
                })
            }

        }
    }

    navigateFolder = (folderName) => {
        let location = this.state.location + "/" + folderName
        let locationSplit = location.split("/")
        locationSplit.splice(0, 2)
        var x = locationSplit.join("/")
        this.setState({
            location: this.state.location + "/" + folderName,
            breadCrumb: x,
            files: []
        })
        this.props.getFolderDetails(this.state.location + "/" + folderName)
        this.props.getFileDetails(x, this.state.experienceName)

    }

    navback = () => {
        let currentLocation = this.state.location;
        let locationSplit = currentLocation.split("/")
        if (locationSplit.length <= 2) {
            this.props.history.push(Constants.ExperienceDashboard)
        } else {
            locationSplit.pop()
            let finalLocation = locationSplit.join("/")
            locationSplit.splice(0, 2)
            var x = locationSplit.join("/")
            this.setState({
                location: finalLocation,
                breadCrumb: x,
                files: []
            }, () => {
                if (this.state.isLinkPanelOpen) {
                    this.setState({
                        isLinkPanelOpen: false
                    }, () => {
                        this.props.history.push(Constants.MediaManagement + "/" + finalLocation)
                    })
                }

                this.props.getFolderDetails(finalLocation)
                this.props.getFileDetails(x, this.state.experienceName)
            })

        }
    }

    linkFile = (mediaId, fileType) => {
        this.setState({
            isLinkPanelOpen: true
        }, () => {
            this.props.history.push(Constants.MediaManagement + "/" + this.state.companyName + "/" + this.state.experienceName + "/" + fileType + "/" + mediaId)
        })
    }

    render() {
        return (
            <div className="ExperienceDashboard mediaManagement">
                <div className="row">
                    <div className="col-12 activityLogDiv">
                        <p className="ActivityLogTitle pt-3 ">Published - 2</p>
                        <div className="actionDiv"></div>
                        <hr className="mt-0" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 expInfo">
                        <div className="whiteBacground">
                            <div className="row">
                                <div className="col-12">
                                    <p className="companyTitle">Company:</p>
                                    <p className="companyName">{this.state.companyName}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className="experienceTitle">Experience Name</p>
                                    <p className="experienceName">{this.state.experienceName}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label className="statusDetails">Status:  <span>Published</span></label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12  fileIcons">
                                    <label><img src={jpgIcon} alt="jpgIcon" /><span>{this.state.jpeg}</span></label>
                                    <label><img src={pngIcon} alt="pngIcon" /><span>{this.state.png}</span></label>
                                    <label><img src={pdfIcon} alt="pdfIcon" /><span>{this.state.pdf}</span></label>
                                    <label><img src={xmlIcon} alt="xmlIcon" /><span>{this.state.xml}</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-9 filesList">
                        <div className="whiteBacground">
                            <div className="row fileslistNavBar">
                                <div className="col-6 text-left">
                                    <label className="backbutton" onClick={this.navback}> <span><img src={dropdownImg} alt="backbutton" /></span>BACK  {(this.state.breadCrumb !== "" && this.state.breadCrumb !== null) ? <span>( {this.state.breadCrumb})</span> : null} </label>
                                </div>
                                <div className="col-6">
                                    {/* <label className="uploadNewFiles"><span><img src={uploadIcon} alt="uploadNewFiles" /></span><button>Upload New Files</button></label> */}
                                </div>
                            </div>
                            <div className="row ">
                                {this.props.filesList.length > 0 ? (
                                    <table className="table filesListDetials">
                                        <thead>
                                            <tr>
                                                <th>File Type</th>
                                                <th>File Path</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.filesList.map((file, index) => {
                                                var location = file.LinkLocation.split("/")
                                                var finalLocation = file.LinkLocation
                                                finalLocation = location.splice(-2).join("/")
                                                return (<tr key={index}>
                                                    <td><div className="linkType">{file.TypeofLink}</div></td>
                                                    <td ><div className="location">{finalLocation}</div></td>
                                                    <td onClick={() => this.linkFile(file.Id, file.TypeofLink)}><div className="linkButtons" >{file.TypeofLink === 'folder' ? "Link Folder" : "Link File"}</div> </td>
                                                </tr>)
                                            })}

                                        </tbody>
                                    </table>

                                ) : null}


                                {this.props.foldersFiles.length > 0 ? (this.props.foldersFiles.map((folderDetails, index) => {
                                    return (
                                        <div key={index} className="col-3 text-center fileDetails" onClick={() => this.navigateFolder(folderDetails.name)}>
                                            <img className="folderIcon" src={folderIcon} alt="newFolderImg" />
                                            <p className="m-0">{folderDetails.name} </p>
                                        </div>
                                    )
                                })) : null}

                                {this.props.foldersFiles.length > 0 ? (<div className="col-3 text-center fileDetails" onClick={() => this.navigateFolder("others")} >
                                    <img className="folderIcon" src={folderIcon} alt="folderIcon" />
                                    <p className="m-0">Others</p>
                                </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MediaManagement